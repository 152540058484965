import React, { useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SetPasswordForm = ({ showMessage, email, showLogin }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      showMessage('Passwords do not match. Please try again.', true);
      return;
    }

    try {
      const response = await fetch('https://api.myexpenditure.com/api8/submit_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password: newPassword })
      });
      if (!response.ok) {
        throw new Error('Failed to set password');
      }
      showMessage('Password set successfully. Please login.');
      showLogin();
    } catch (error) {
      showMessage('An error occurred while setting the password.', true);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Form className="form">
      <Form.Group controlId="formEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" value={email} readOnly />
      </Form.Group>
      <Form.Group controlId="formNewPassword">
        <Form.Label>New Password</Form.Label>
        <InputGroup>
          <Form.Control 
            type={showPassword ? 'text' : 'password'} 
            value={newPassword} 
            onChange={(e) => setNewPassword(e.target.value)} 
            required 
          />
          <InputGroup.Text onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="formConfirmPassword">
        <Form.Label>Confirm Password</Form.Label>
        <InputGroup>
          <Form.Control 
            type={showPassword ? 'text' : 'password'} 
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)} 
            required 
          />
          <InputGroup.Text onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <Button variant="primary" onClick={handleSubmit} style={{ marginTop: '20px' }}>
        Set Password
      </Button>
      <Button variant="primary" onClick={showLogin} style={{ marginLeft: '20px', marginTop: '20px' }}>
        Login
      </Button>
    </Form>
  );
};

export default SetPasswordForm;
