import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const RegisterForm = ({ showMessage, onRegister, showLogin }) => {
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://api.myexpenditure.com/api6/get_otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          contact,
          email,
          request_type: 'register'
        })
      });
      if (!response.ok) {
        throw new Error('Failed to send OTP');
      }
      showMessage('OTP sent to your email address.');
      onRegister(email);
    } catch (error) {
      showMessage('An error occurred while sending OTP.', true);
    }
  };

  return (
    <Form className="form">
      <Form.Group controlId="formName">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required />
      </Form.Group>
      <Form.Group controlId="formContact">
        <Form.Label>Contact Number</Form.Label>
        <Form.Control type="tel" value={contact} onChange={(e) => setContact(e.target.value)} required />
      </Form.Group>
      <Form.Group controlId="formEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </Form.Group>
      <Button variant="primary" onClick={handleSubmit} style={{ marginTop: '20px' }}>
        Submit Email
      </Button>
      <Button variant="primary" onClick={showLogin} style={{marginLeft:'20px', marginTop: '20px' }}>
        Login
      </Button>
    </Form>
  );
};

export default RegisterForm;
