import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import OTPForm from './components/OTPForm';
import SetPasswordForm from './components/SetPasswordForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ForgotOTPForm from './components/ForgotOTPForm';
import ForgotSetPasswordForm from './components/ForgotSetPasswordForm';
import UploadForm from './components/UploadForm';
import OutputSection from './components/OutputSection';
import AnalysisSection from './components/AnalysisSection';
import MessageBox from './components/MessageBox';
import Overview from './components/Overview';

import './App.css';

const App = () => {
  const [activeForm, setActiveForm] = useState(localStorage.getItem('activeForm') || 'login');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [loggedInEmail, setLoggedInEmail] = useState(localStorage.getItem('loggedInEmail') || '');
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('isAuthenticated'));

  const showMessage = (message, isError = false) => {
    setMessage(message);
    setIsError(isError);
    setTimeout(() => {
      setMessage('');
    }, 10000);
  };

  const handleLogin = (email) => {
    setLoggedInEmail(email);
    setIsAuthenticated(true);
    setActiveForm('overview');
    localStorage.setItem('loggedInEmail', email);
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('activeForm', 'overview');
  };

  const handleRegister = (email) => {
    setLoggedInEmail(email);
    setActiveForm('otp');
    localStorage.setItem('activeForm', 'otp');
  };

  const handleForgotPassword = (email) => {
    setLoggedInEmail(email);
    setActiveForm('forgotOtp');
    localStorage.setItem('activeForm', 'forgotOtp');
  };

  const handleLogout = () => {
    setLoggedInEmail('');
    setIsAuthenticated(false);
    setActiveForm('login');
    localStorage.removeItem('loggedInEmail');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('activeForm');
  };

  useEffect(() => {
    const savedActiveForm = localStorage.getItem('activeForm');
    if (savedActiveForm) {
      setActiveForm(savedActiveForm);
      console.log(`Restored activeForm: ${savedActiveForm}`);
    }
    console.log(`Restored isAuthenticated: ${localStorage.getItem('isAuthenticated')}`);
    console.log(`Restored loggedInEmail: ${localStorage.getItem('loggedInEmail')}`);
  }, []);

  const handleNavigation = (form) => {
    setActiveForm(form);
    localStorage.setItem('activeForm', form);
  };
  const navigateToCategorize = () => {
    setActiveForm('output');
  };

  return (
    <GoogleOAuthProvider clientId="976762894616-n87o3qqk8pu841sr3qb740j0hjq14ntu.apps.googleusercontent.com">
      <div className="App">
        <Navbar bg="dark" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand href="#">My Expenditure</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {isAuthenticated ? (
                  <>
                    <Nav.Link
                      onClick={() => handleNavigation('overview')}
                      className={activeForm === 'overview' ? 'active-nav-link' : ''}
                    >
                      Overview
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => handleNavigation('upload')}
                      className={activeForm === 'upload' ? 'active-nav-link' : ''}
                    >
                      Upload
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => handleNavigation('output')}
                      className={activeForm === 'output' ? 'active-nav-link' : ''}
                    >
                      Categorize
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => handleNavigation('analysis')}
                      className={activeForm === 'analysis' ? 'active-nav-link' : ''}
                    >
                      Analysis
                    </Nav.Link>
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                  </>
                ) : (
                  <>
                    <Nav.Link
                      onClick={() => handleNavigation('login')}
                      className={activeForm === 'login' ? 'active-nav-link' : ''}
                    >
                      Login
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => handleNavigation('register')}
                      className={activeForm === 'register' ? 'active-nav-link' : ''}
                    >
                      Register
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => handleNavigation('forgot')}
                      className={activeForm === 'forgot' ? 'active-nav-link' : ''}
                    >
                      Forgot Password
                    </Nav.Link>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container className="mt-4">
          {message && <MessageBox message={message} isError={isError} />}
          {activeForm === 'login' && <LoginForm showMessage={showMessage} onLogin={handleLogin} />}
          {activeForm === 'register' && <RegisterForm showMessage={showMessage} onRegister={handleRegister} showLogin={() => setActiveForm('login')} />}
          {activeForm === 'otp' && <OTPForm showMessage={showMessage} email={loggedInEmail} showSetPassword={() => setActiveForm('setPassword')} showLogin={() => setActiveForm('login')} />}
          {activeForm === 'setPassword' && <SetPasswordForm showMessage={showMessage} email={loggedInEmail} showLogin={() => setActiveForm('login')} />}
          {activeForm === 'forgot' && <ForgotPasswordForm showMessage={showMessage} onForgotPassword={handleForgotPassword} showLogin={() => setActiveForm('login')} />}
          {activeForm === 'forgotOtp' && <ForgotOTPForm showMessage={showMessage} email={loggedInEmail} showForgotSetPassword={() => setActiveForm('forgotSetPassword')} showLogin={() => setActiveForm('login')} />}
          {activeForm === 'forgotSetPassword' && <ForgotSetPasswordForm showMessage={showMessage} email={loggedInEmail} showLogin={() => setActiveForm('login')} />}
          {activeForm === 'upload' && <UploadForm showMessage={showMessage} showSubmit={() => handleNavigation('output')} loggedInEmail={loggedInEmail} />}
          {activeForm === 'output' && <OutputSection showMessage={showMessage} loggedInEmail={loggedInEmail} />}
          {activeForm === 'analysis' && <AnalysisSection loggedInEmail={loggedInEmail} />}
          {activeForm === 'overview' && <Overview loggedInEmail={loggedInEmail} navigateToCategorize={navigateToCategorize} />}
        </Container>
      </div>
    </GoogleOAuthProvider>
  );
};

export default App;