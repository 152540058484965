import React, { useEffect, useState, useMemo } from 'react';
import { Card, Row, Col, Container, Form, Dropdown } from 'react-bootstrap';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { format, startOfMonth, endOfMonth, subMonths, startOfYear, isWithinInterval, parseISO, subDays } from 'date-fns';
import { ChevronDown } from 'lucide-react';
import styled from 'styled-components';
import RecentTransactions from './RecentTransactions';

const GreetingContainer = styled.div`
  margin-bottom: 24px;
  animation: fadeIn 0.5s ease-out;

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;

const GreetingText = styled.h1`
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
`;

const DateText = styled.p`
  font-size: 18px;
  color: #666;
`;



const EnhancedGreeting = ({ username }) => {
  const [greeting, setGreeting] = useState('');
  const [emoji, setEmoji] = useState('');

  useEffect(() => {
    const hour = new Date().getHours();
    if (hour < 12) {
      setGreeting('Good Morning');
      setEmoji('☀️');
    } else if (hour < 18) {
      setGreeting('Good Afternoon');
      setEmoji('🌤️');
    } else {
      setGreeting('Good Evening');
      setEmoji('🌙');
    }
  }, []);

  const formattedDate = format(new Date(), "do 'of' MMMM, EEEE");

  return (
    <GreetingContainer>
      <GreetingText>{emoji} {greeting} !</GreetingText>
      <DateText>It's a beautiful {formattedDate} 🌟</DateText>
    </GreetingContainer>
  );
};

const Overview = ({ loggedInEmail,navigateToCategorize }) => {
  const [overviewData, setOverviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedOverviewBanks, setSelectedOverviewBanks] = useState([]);
  const [selectedCashFlowBanks, setSelectedCashFlowBanks] = useState([]);
  const [hoveredValue, setHoveredValue] = useState(null);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState('latest_available_month');
  const [customDateRange, setCustomDateRange] = useState({ start: null, end: null });
  const [latestDataDate, setLatestDataDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [cashFlowHeader, setCashFlowHeader] = useState('CASH FLOW');
  const [selectedTransactionBanks, setSelectedTransactionBanks] = useState([]);
  const [selectedTransactionType, setSelectedTransactionType] = useState('All transactions');

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        const response = await fetch('https://api.myexpenditure.com/api9/get_overview', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: loggedInEmail }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch overview data');
        }

        const data = await response.json();
        const allBanks = [...new Set(data.data.timeline_data.map(item => item.bank_name))];
        setSelectedOverviewBanks(allBanks);
        setSelectedCashFlowBanks(allBanks);
        setSelectedTransactionBanks(allBanks);
        setOverviewData(data.data);

        const lastDate = new Date(Math.max(...data.data.timeline_data.map(item => new Date(item.date))));
        setLatestDataDate(lastDate);
        setHoveredValue(calculateTotalClosingBalance(data.data.timeline_data, allBanks));
        
        updateCashFlowHeader('latest_available_month', lastDate);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching overview data:', error);
        setLoading(false);
      }
    };

    if (loggedInEmail) {
      fetchOverviewData();
    }
  }, [loggedInEmail]);

  const allBanks = useMemo(() => {
    if (!overviewData || !overviewData.timeline_data) return [];
    return [...new Set(overviewData.timeline_data.map(item => item.bank_name))];
  }, [overviewData]);

  const filterDataByDateRange = (data, range) => {
    if (!data || data.length === 0 || !latestDataDate) return [];
    let startDate, endDate;

    switch (range) {
      case 'latest_available_month':
        startDate = startOfMonth(latestDataDate);
        endDate = latestDataDate;
        break;
      case 'last_month':
        startDate = startOfMonth(subMonths(latestDataDate, 1));
        endDate = endOfMonth(subMonths(latestDataDate, 1));
        break;
      case 'last_6_months':
        startDate = startOfMonth(subMonths(latestDataDate, 6));
        endDate = latestDataDate;
        break;
      case 'current_year':
        startDate = startOfYear(latestDataDate);
        endDate = latestDataDate;
        break;
      case 'all_time':
        startDate = new Date(0); // Earliest possible date
        endDate = latestDataDate;
        break;
      case 'custom':
        startDate = customDateRange.start ? parseISO(customDateRange.start) : latestDataDate;
        endDate = customDateRange.end ? parseISO(customDateRange.end) : latestDataDate;
        break;
      default:
        startDate = startOfMonth(latestDataDate);
        endDate = latestDataDate;
    }

    return data.filter(item =>
      isWithinInterval(new Date(item.date), { start: startDate, end: endDate })
    );
  };

  const calculateCashFlow = useMemo(() => {
    return (data) => {
      if (!data) return { incoming: 0, outgoing: 0, invested: 0, left: 0 };

      const filteredData = filterDataByDateRange(data, selectedDateRange)
        .filter(item => selectedCashFlowBanks.includes(item.bank_name));
      
      const incoming = filteredData.reduce((sum, item) => sum + (item.credit_amount || 0), 0);
      const outgoing = filteredData.reduce((sum, item) => sum + (item.debit_amount || 0), 0);
      const invested = 0;
      const left = incoming - outgoing > 0 ? incoming - outgoing : 0;

      return { incoming, outgoing, invested, left };
    };
  }, [selectedDateRange, customDateRange, selectedCashFlowBanks, latestDataDate]);

  const updateDailyLineChart = useMemo(() => {
    return (data, selectedBanks) => {
      if (!data || selectedBanks.length === 0) return { x: [], closingBalances: [] };

      const filteredData = data.filter(item => selectedBanks.includes(item.bank_name));

      const groupedData = filteredData.reduce((acc, item) => {
        const date = item.date;
        if (!acc[date]) acc[date] = [];
        acc[date].push(item);
        return acc;
      }, {});

      const dates = Object.keys(groupedData).sort();

      const latestDate = new Date(dates[dates.length - 1]);
      const thirtyDaysAgo = subDays(latestDate, 30);
      const closestDate = dates
        .map(dateStr => new Date(dateStr))
        .filter(date => date <= thirtyDaysAgo)
        .sort((a, b) => b - a)[0];

      const startDate = closestDate || new Date(dates[0]);

      let lastKnownBalances = {};
      const closingBalances = [];
      const filteredDates = [];

      dates.forEach(dateKey => {
        const currentDate = new Date(dateKey);
        let closingBalanceForDate = 0;

        selectedBanks.forEach(bankName => {
          const accountTransactions = groupedData[dateKey]?.filter(item => item.bank_name === bankName) || [];
          if (accountTransactions.length > 0) {
            const maxIdTransaction = accountTransactions.reduce((max, t) => t.id > max.id ? t : max);
            lastKnownBalances[bankName] = maxIdTransaction.closing_balance;
          }
          closingBalanceForDate += lastKnownBalances[bankName] || 0;
        });

        if (currentDate >= startDate) {
          closingBalances.push(closingBalanceForDate);
          filteredDates.push(dateKey);
        }
      });

      return { x: filteredDates, closingBalances };
    };
  }, []);

  const calculateBankClosingBalance = (bankName) => {
    if (!overviewData || !overviewData.timeline_data) return 0;

    const filteredData = overviewData.timeline_data.filter(item => item.bank_name === bankName);
    if (filteredData.length === 0) return 0;

    const latestTransaction = filteredData.reduce((latest, item) => new Date(item.date) > new Date(latest.date) ? item : latest);
    return latestTransaction.closing_balance;
  };

  const calculateTotalClosingBalance = (data, banks) => {
    return banks.reduce((total, bank) => total + calculateBankClosingBalance(bank), 0);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 0 }).format(amount).replace('₹', '₹ ');
  };

  const handleBankSelect = (selectedBanks, setSelectedBanks) => (bankName) => {
    setSelectedBanks((prev) => {
      if (prev.length === 1 && prev.includes(bankName)) {
        return prev;
      }

      if (bankName === 'ALL BANKS') {
        return prev.length === allBanks.length ? [] : allBanks;
      } else {
        if (prev.includes(bankName)) {
          return prev.filter((b) => b !== bankName);
        } else {
          return [...prev, bankName];
        }
      }
    });
  };

  const handleOverviewBankSelect = handleBankSelect(selectedOverviewBanks, setSelectedOverviewBanks);
  const handleCashFlowBankSelect = handleBankSelect(selectedCashFlowBanks, setSelectedCashFlowBanks);

  const handleHover = (event) => {
    const { activePayload } = event;

    if (activePayload && activePayload.length > 0) {
      const { payload } = activePayload[0];
      if (payload && payload.balance) {
        setHoveredValue(payload.balance);
        setHoveredDate(payload.date);
      }
    }
  };

  const handleUnhover = () => {
    setHoveredValue(null);
    setHoveredDate(null);
  };

  const updateCashFlowHeader = (range, date = latestDataDate) => {
    if (!date) return;

    switch (range) {
      case 'latest_available_month':
        setCashFlowHeader(`CASH FLOW (${format(date, 'MMMM yyyy')})`);
        break;
      case 'last_month':
        const lastMonth = subMonths(date, 1);
        setCashFlowHeader(`CASH FLOW (${format(lastMonth, 'MMMM yy')})`);
        break;
      case 'last_6_months':
        const sixMonthsAgo = subMonths(date, 6);
        setCashFlowHeader(`CASH FLOW (${format(sixMonthsAgo, 'MMMM yy')} - ${format(date, 'MMMM yy')})`);
        break;
      case 'current_year':
        setCashFlowHeader(`CASH FLOW (${format(date, 'yyyy')})`);
        break;
      case 'all_time':
        setCashFlowHeader('CASH FLOW (All Time)');
        break;
      case 'custom':
        if (customDateRange.start && customDateRange.end) {
          const start = parseISO(customDateRange.start);
          const end = parseISO(customDateRange.end);
          setCashFlowHeader(`CASH FLOW (${format(start, 'MMM yy')} - ${format(end, 'MMM yy')})`);
        } else {
          setCashFlowHeader('CASH FLOW (Custom Range)');
        }
        break;
      default:
        setCashFlowHeader('CASH FLOW');
    }
  };

  const handleDateRangeChange = (range) => {
    setSelectedDateRange(range);
    setShowDatePicker(range === 'custom');
    updateCashFlowHeader(range);
  };

  const handleDateChange = (e) => {
    const newCustomDateRange = { ...customDateRange, [e.target.name]: e.target.value };
    setCustomDateRange(newCustomDateRange);
    if (newCustomDateRange.start && newCustomDateRange.end) {
      updateCashFlowHeader('custom');
    }
  };

  const prepareRecentTransactions = useMemo(() => {
    if (!overviewData || !overviewData.timeline_data) return [];
    return overviewData.timeline_data
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .map(transaction => ({
        id: transaction.id,
        description: transaction.narration,
        date: transaction.date,
        amount: transaction.credit_amount || -transaction.debit_amount,
        category: transaction.model_classified_cat_type,
        bank_name: transaction.bank_name
      })); // Get the 10 most recent transactions
  }, [overviewData]);

  const handleTransactionBankSelect = (bankName) => {
    setSelectedTransactionBanks((prev) => {
      if (prev.length === 1 && prev.includes(bankName)) {
        return prev;
      }

      if (bankName === 'ALL BANKS') {
        return prev.length === allBanks.length ? [] : allBanks;
      } else {
        if (prev.includes(bankName)) {
          return prev.filter((b) => b !== bankName);
        } else {
          return [...prev, bankName];
        }
      }
    });
  };

  const filteredTransactions = useMemo(() => {
    return prepareRecentTransactions.filter(transaction => {
      const bankMatch = selectedTransactionBanks.includes(transaction.bank_name);
      const typeMatch = selectedTransactionType === 'All transactions' || 
                        (selectedTransactionType === 'Credit' && transaction.amount > 0) ||
                        (selectedTransactionType === 'Debit' && transaction.amount < 0);
      return bankMatch && typeMatch;
    });
  }, [prepareRecentTransactions, selectedTransactionBanks, selectedTransactionType]);

  const handleTransactionTypeSelect = (type) => {
    setSelectedTransactionType(type);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!overviewData) {
    return <div>No data available. Please upload data in the Upload Section.</div>;
  }

  const chartData = updateDailyLineChart(overviewData.timeline_data, selectedOverviewBanks);
  const cashFlowData = calculateCashFlow(overviewData.timeline_data);

  return (
    <Container fluid style={styles.container}>
      <EnhancedGreeting username={loggedInEmail} />

      <Row>
        <Col md={8}>
          <Card style={styles.card}>
            <Row>
              <Col md={4} style={styles.bankSection}>
                <h5 style={styles.bankHeading}>BANKS</h5>
                {allBanks.map((bank, index) => (
                  <div key={index} style={styles.bankCard}>
                    <Form.Check
                      type="checkbox"
                      id={`bank-${index}`}
                      label={bank}
                      checked={selectedOverviewBanks.includes(bank)}
                      onChange={() => handleOverviewBankSelect(bank)}
                      style={styles.bankName}
                    />
                    <span style={styles.bankBalance}>{formatCurrency(calculateBankClosingBalance(bank))}</span>
                  </div>
                ))}
              </Col>

              <Col md={8} style={styles.cumulativeCashSection}>
                <h5 style={styles.cumulativeCashHeading}>CUMULATIVE CASH</h5>
                <div style={styles.cumulativeCashContainer}>
                  <div style={styles.cumulativeCashValue}>
                    {formatCurrency(hoveredValue || calculateTotalClosingBalance(overviewData.timeline_data, selectedOverviewBanks))}
                  </div>
                  <div style={styles.cumulativeCashDate}>
                    {hoveredDate
                      ? `on ${format(new Date(hoveredDate), "EEEE, do MMMM")}`
                      : '\u00A0'}
                  </div>
                </div>
                <ResponsiveContainer width="100%" height={200}>
                  <AreaChart
                    data={chartData.x.map((date, index) => ({ date, balance: chartData.closingBalances[index] }))}
                    onMouseMove={handleHover}
                    onMouseLeave={handleUnhover}
                    
                  >
                    <defs>
                      <linearGradient id="colorBalance" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#4CAF50" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#4CAF50" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                      <XAxis 
                        dataKey="date" 
                        tickFormatter={(tick) => format(new Date(tick), 'MMM d')}
                        interval="preserveStartEnd"
                        minTickGap={30}
                        
                      
                      />
                      <YAxis hide />
                      <Tooltip 
                        labelFormatter={(label) => format(new Date(label), 'MMMM d, yyyy')}
                        formatter={(value) => formatCurrency(value)}
                    
                      />
                      <Area type="monotone" dataKey="balance" stroke="#4CAF50" fillOpacity={1} fill="url(#colorBalance)" />
                    </AreaChart>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={4}>
            <Card style={styles.card}>
              <h5 style={styles.bankHeading}>{cashFlowHeader}</h5>
              <Row style={styles.filterRow}>
                <Col xs={6}>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="bank-filter-dropdown" style={styles.filterDropdown}>
                      {selectedCashFlowBanks.length === allBanks.length ? 'All banks' : `${selectedCashFlowBanks.length} selected`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as="button" onClick={() => handleCashFlowBankSelect('ALL BANKS')}>
                        <Form.Check
                          type="checkbox"
                          checked={selectedCashFlowBanks.length === allBanks.length}
                          onChange={() => {}}
                          label="ALL BANKS"
                        />
                      </Dropdown.Item>
                      {allBanks.map((bank) => (
                        <Dropdown.Item key={bank} as="button" onClick={() => handleCashFlowBankSelect(bank)}>
                          <Form.Check
                            type="checkbox"
                            checked={selectedCashFlowBanks.includes(bank)}
                            onChange={() => {}}
                            label={bank}
                          />
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs={6}>
                  <Dropdown onSelect={handleDateRangeChange}>
                    <Dropdown.Toggle variant="light" id="dropdown-basic" style={styles.filterDropdown}>
                      {selectedDateRange === 'latest_available_month' ? format(latestDataDate, 'MMM yyyy') :
                      selectedDateRange === 'last_month' ? 'Last Month' :
                      selectedDateRange === 'last_6_months' ? 'Last 6 Months' :
                      selectedDateRange === 'current_year' ? 'Current Year' :
                      selectedDateRange === 'all_time' ? 'All Time' :
                      'Custom Range'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="latest_available_month">{format(latestDataDate, 'MMM yyyy')}</Dropdown.Item>
                      <Dropdown.Item eventKey="last_month">Last Month</Dropdown.Item>
                      <Dropdown.Item eventKey="last_6_months">Last 6 Months</Dropdown.Item>
                      <Dropdown.Item eventKey="current_year">Current Year</Dropdown.Item>
                      <Dropdown.Item eventKey="all_time">All Time</Dropdown.Item>
                      <Dropdown.Item eventKey="custom">Custom Range</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              {showDatePicker && (
                <div style={styles.datePicker}>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="4">Start Date</Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="date"
                        name="start"
                        value={customDateRange.start || ''}
                        onChange={handleDateChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="4">End Date</Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="date"
                        name="end"
                        value={customDateRange.end || ''}
                        onChange={handleDateChange}
                      />
                    </Col>
                  </Form.Group>
                </div>
              )}
              <div style={styles.cashFlowSection}>
                <div style={styles.cashFlowItem}>
                  <span style={styles.cashFlowLabel}>INCOMING</span>
                  <span style={styles.cashFlowValue(true)}>+ {formatCurrency(cashFlowData.incoming)}</span>
                </div>
                <div style={styles.cashFlowItem}>
                  <span style={styles.cashFlowLabel}>OUTGOING</span>
                  <span style={styles.cashFlowValue(false)}>- {formatCurrency(cashFlowData.outgoing)}</span>
                </div>
                <div style={styles.cashFlowItem}>
                  <span style={styles.cashFlowLabel}>INVESTED</span>
                  <span style={styles.cashFlowValue(false, '#3b82f6')}>- {formatCurrency(cashFlowData.invested)}</span>
                </div>
                <div style={styles.cashFlowItem}>
                  <span style={styles.cashFlowLabel}>LEFT</span>
                  <span style={styles.cashFlowValue(true)}>{formatCurrency(cashFlowData.left)}</span>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        
        {/* <Row className="mt-4">
          <Col>
            <Card style={styles.card}>
              <Card.Body>
                <div style={styles.recentTransactionsHeader}>
                  <h5 style={styles.bankHeading}>RECENT TRANSACTIONS</h5>
                  <button style={styles.seeAllButton}>SEE ALL</button>
                </div>
                <div style={styles.filterRow}>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-transaction-type" style={styles.filterDropdown}>
                      {selectedTransactionType} <ChevronDown size={16} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleTransactionTypeSelect('All transactions')}>All transactions</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleTransactionTypeSelect('Credit')}>Credit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleTransactionTypeSelect('Debit')}>Debit</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="transaction-bank-filter-dropdown" style={styles.filterDropdown}>
                      {selectedTransactionBanks.length === allBanks.length ? 'All banks' : `${selectedTransactionBanks.length} selected`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as="button" onClick={() => handleTransactionBankSelect('ALL BANKS')}>
                        <Form.Check
                          type="checkbox"
                          checked={selectedTransactionBanks.length === allBanks.length}
                          onChange={() => {}}
                          label="ALL BANKS"
                        />
                      </Dropdown.Item>
                      {allBanks.map((bank) => (
                        <Dropdown.Item key={bank} as="button" onClick={() => handleTransactionBankSelect(bank)}>
                          <Form.Check
                            type="checkbox"
                            checked={selectedTransactionBanks.includes(bank)}
                            onChange={() => {}}
                            label={bank}
                          />
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {filteredTransactions.slice(0, 5).map((transaction) => (
                  <div key={transaction.id} style={styles.transactionItem}>
                    <div style={styles.transactionLeftContent}>
                      <p style={styles.transactionName}>{transaction.description}</p>
                      <p style={styles.transactionDate}>
                        {format(new Date(transaction.date), "EEEE, do MMMM")}
                      </p>
                    </div>
                    <div style={styles.transactionRightContent}>
                      <p style={styles.transactionAmount(transaction.amount > 0)}>
                        {transaction.amount > 0 ? '+' : '-'}{formatCurrency(Math.abs(transaction.amount))}
                      </p>
                      <span style={styles.transactionCategory}>{transaction.category}</span>
                    </div>
                  </div>
                ))}
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
        <Row className="mt-4">
          <Col>
            <RecentTransactions
              filteredTransactions={filteredTransactions}
              selectedTransactionType={selectedTransactionType}
              selectedTransactionBanks={selectedTransactionBanks}
              handleTransactionTypeSelect={handleTransactionTypeSelect}
              handleTransactionBankSelect={handleTransactionBankSelect}
              allBanks={allBanks}
              formatCurrency={formatCurrency}
              navigateToCategorize={navigateToCategorize}
            />
          </Col>
        </Row>
      </Container>
    );
  };
  
  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      padding: '20px',
      backgroundColor: '#f5f5f5',
    },
    card: {
      borderRadius: '16px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#ffffff',
      padding: '24px',
      height: '100%',
    },
    heading: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#333',
      marginBottom: '5px',
    },
    subheading: {
      fontSize: '16px',
      color: '#666',
      marginBottom: '20px',
    },
    bankSection: {
      marginBottom: '20px',
    },
    bankHeading: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#555',
      marginBottom: '10px',
    },
    bankCard: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 15px',
      backgroundColor: '#f0f0f0',
      borderRadius: '5px',
      marginBottom: '10px',
    },
    bankName: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#333',
    },
    bankBalance: {
      fontSize: '16px',
      color: '#0066cc',
    },
    cumulativeCashSection: {
      marginBottom: '20px',
    },
    cumulativeCashHeading: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#555',
      marginBottom: '10px',
    },
    cumulativeCashContainer: {
      height: '100px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    cumulativeCashValue: {
      fontSize: '36px',
      fontWeight: 'bold',
      color: '#0066cc',
      marginBottom: '5px',
    },
    cumulativeCashDate: {
      fontSize: '14px',
      color: '#666',
      height: '20px',
    },
    cashFlowSection: {
      marginTop: '20px',
    },
    cashFlowItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '15px',
    },
    cashFlowLabel: {
      fontSize: '14px',
      color: '#6b7280',
    },
    cashFlowValue: (isPositive, color = null) => ({
      fontSize: '18px',
      fontWeight: 'bold',
      color: color ? color : isPositive ? '#10b981' : '#ef4444',
    }),
    dateRangeSelector: {
      marginBottom: '20px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    datePicker: {
      marginTop: '10px',
    },
    
  };

  
  export default Overview;