import React from 'react';
import { Button } from 'react-bootstrap';

const ValidateButton = ({ data, userValidatedData, loggedInEmail, showMessage, className }) => {
  const validateData = async () => {
    const validatedData = data.map((row, index) => ({
      ...row,
      userValidatedTrType: userValidatedData[index]?.userValidatedTrType || '',
      userValidatedCatType: userValidatedData[index]?.userValidatedCatType || '',
    }));

    const validationData = {
      email: loggedInEmail,
      data: validatedData
    };

    try {
      const response = await fetch('https://api.myexpenditure.com/api4/update_db_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(validationData)
      });
      if (!response.ok) {
        throw new Error('Failed to update data');
      }
      const data = await response.json();
      console.log('Data validation & updation success:', data);
      showMessage('Data Validated and updated successfully!');
    } catch (error) {
      console.error('Error:', error);
      showMessage('An error occurred while updating and validating the data.', true);
    }
  };

  return (
    <Button variant="warning" onClick={validateData} className={className}>
      Validate
    </Button>
  );
};

export default ValidateButton;
