import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './AnalysisSection.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Pagination from 'react-bootstrap/Pagination';

const paginationOptions = {
  custom: true,
  page: 1,
  sizePerPage: 5,
  paginationSize: 3,
  pageStartIndex: 1,
  firstPageText: 'First',
  prePageText: 'Prev',
  nextPageText: 'Next',
  lastPageText: 'Last',
  nextPageTitle: 'Next page',
  prePageTitle: 'Previous page',
  firstPageTitle: 'First page',
  lastPageTitle: 'Last page',
  showTotal: true,
  hideSizePerPage: true,
  sizePerPageList: [{ text: '5', value: 5 }]
};

const paginationOptions2 = {
  custom: true,
  page: 1,
  sizePerPage: 20,
  paginationSize: 3,
  pageStartIndex: 1,
  firstPageText: 'First',
  prePageText: 'Prev',
  nextPageText: 'Next',
  lastPageText: 'Last',
  nextPageTitle: 'Next page',
  prePageTitle: 'Previous page',
  firstPageTitle: 'First page',
  lastPageTitle: 'Last page',
  showTotal: true,
  hideSizePerPage: true,
  sizePerPageList: [{ text: '20', value: 20 }]
};

const AnalysisSection = ({ loggedInEmail }) => {
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tempdata, setData2] = useState([]);
  const [sortConfig] = useState({ key: null, direction: 'ascending' });
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
  const [filters, setFilters] = useState({
    selectedBanks: [],
    selectedAccounts: [],
    selectedCategories: [],
    selectedTransactions: [],
    dateRange: { startDate: null, endDate: null },
  });
  const [scorecards, setScorecards] = useState({});
  const [timeBasis, setTimeBasis] = useState('Monthly');
  const [visibleLines, setVisibleLines] = useState({
    credit: false,
    debit: false,
    net: true,
    closing: true,
  });
  const [pieChartBasis, setPieChartBasis] = useState('model_classified_cat_type');
  const [creditOthers, setCreditOthers] = useState([]);
  const [debitOthers, setDebitOthers] = useState([]);
  const [othersCategories, setOthersCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.post('https://api.myexpenditure.com/api5/get_raw_data', { email: loggedInEmail });
      const rawData = response.data.raw_data;
      setOriginalData(rawData);
      if (rawData.length > 0) {
        const minDate = new Date(Math.min(...rawData.map(item => new Date(item.date))));
        const maxDate = new Date(Math.max(...rawData.map(item => new Date(item.date))));
        setFilters(filters => ({
          ...filters,
          dateRange: { startDate: minDate, endDate: maxDate }
        }));
        updateScorecards(rawData, rawData);
      }
    } catch (error) {
      console.error('Error fetching data', error);
    }
  }, [loggedInEmail]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const applyFilters = useCallback(() => {
    let data = [...originalData];
    let data2 = [...originalData];
    if (filters.selectedBanks.length > 0) {
      data = data.filter(item => filters.selectedBanks.some(bank => bank.value === item.bank_name));
      data2 = data2.filter(item => filters.selectedBanks.some(bank => bank.value === item.bank_name));
    }
    if (filters.selectedAccounts.length > 0) {
      data = data.filter(item => filters.selectedAccounts.some(account => account.value === item.account_number));
      data2 = data2.filter(item => filters.selectedAccounts.some(account => account.value === item.account_number));
    }
    if (filters.selectedCategories.length > 0) {
      data = data.filter(item => filters.selectedCategories.some(category => category.value === item.model_classified_cat_type));
      data2 = data2.filter(item => filters.selectedCategories.some(category => category.value === item.model_classified_cat_type));
    }
    if (filters.selectedTransactions.length > 0) {
      data = data.filter(item => filters.selectedTransactions.some(transaction => transaction.value === item.model_classified_tr_type));
      data2 = data2.filter(item => filters.selectedTransactions.some(transaction => transaction.value === item.model_classified_tr_type));
    }
    if (filters.dateRange.startDate && filters.dateRange.endDate) {
      data = data.filter(item => {
        const date = new Date(item.date);
        return date >= filters.dateRange.startDate && date <= filters.dateRange.endDate;
      });
      data2 = data2.filter(item => {
        const date = new Date(item.date);
        return date <= filters.dateRange.endDate;
      });
    }

    setFilteredData(data);
    setData2(data2);
    updateScorecards(data, data2);

    const creditResult = getTop5Categories(data.filter(item => item.credit_amount > 0), 'credit_amount', pieChartBasis);
    const debitResult = getTop5Categories(data.filter(item => item.debit_amount > 0), 'debit_amount', pieChartBasis);

    setCreditOthers(creditResult.others);
    setDebitOthers(debitResult.others);
    setOthersCategories(creditResult.others.concat(debitResult.others).map(cat => cat.label));
  }, [filters, originalData, pieChartBasis]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const updateScorecards = useCallback((data, data2) => {
    const totalCreditAmount = data.reduce((sum, item) => sum + item.credit_amount, 0);
    const totalDebitAmount = data.reduce((sum, item) => sum + item.debit_amount, 0);
    const totalTransactionCount = data.length;
    const totalBankCount = [...new Set(data.map(item => item.bank_name))].length;
  
    let closingBalance = 0;
  
    if (data2.length > 0) {
      const getAccountKey = (item) => `${item.account_number}|${item.bank_name}`;
      const accountKeys = [...new Set(data2.map(getAccountKey))];
      const groupedData = data2.reduce((acc, item) => {
        const key = getAccountKey(item);
        if (!acc[key]) {
          acc[key] = {};
        }
        if (!acc[key][item.date]) {
          acc[key][item.date] = [];
        }
        acc[key][item.date].push(item);
        return acc;
      }, {});
  
      const mostRecentDates = accountKeys.reduce((acc, key) => {
        const dates = Object.keys(groupedData[key]).sort((a, b) => new Date(b) - new Date(a));
        acc[key] = dates[0];
        return acc;
      }, {});
  
      closingBalance = accountKeys.reduce((total, key) => {
        const recentDate = mostRecentDates[key];
        if (recentDate) {
          const transactions = groupedData[key][recentDate];
          const maxIdTransaction = transactions.reduce((max, t) => t.id > max.id ? t : max);
          return total + maxIdTransaction.closing_balance;
        }
        return total;
      }, 0);
    }
  
    setScorecards({
      totalCreditAmount,
      totalDebitAmount,
      totalTransactionCount,
      totalBankCount,
      netBalance: totalCreditAmount - totalDebitAmount,
      closingBalance
    });
  }, []);

  const updateLineChart = useMemo(() => {
    return (data, data2) => {
      let groupedData = {};
      let groupedData2 = {};
  
      const getTimeKey = (date) => {
        if (timeBasis === 'Daily') {
          return date; // Return the full date for daily basis
        } else if (timeBasis === 'Monthly') {
          return date.slice(0, 7);
        } else if (timeBasis === 'Quarterly') {
          const [year, month] = date.split('-');
          const quarter = Math.ceil(parseInt(month) / 3);
          return `${year}-Q${quarter}`;
        } else if (timeBasis === 'Yearly') {
          return date.slice(0, 4);
        }
      };
  
      // Group data based on timeBasis
      data.forEach(item => {
        const key = getTimeKey(item.date);
        if (!groupedData[key]) groupedData[key] = [];
        groupedData[key].push(item);
      });
  
      data2.forEach(item => {
        const key = getTimeKey(item.date);
        if (!groupedData2[key]) groupedData2[key] = [];
        groupedData2[key].push(item);
      });
  
      const dates = Object.keys(groupedData).sort();
      const getAccountKey = (item) => `${item.account_number}|${item.bank_name}`;
      const accountKeys = [...new Set(data2.map(getAccountKey))];
  
      // Create a map to store the most recent balance for each account
      let lastKnownBalances = {};
  
      const closingBalances = dates.map(dateKey => {
        let closingBalanceForDate = 0;
  
        accountKeys.forEach(accountKey => {
          const accountTransactions = groupedData2[dateKey]?.filter(item => getAccountKey(item) === accountKey) || [];
          if (accountTransactions.length > 0) {
            // Get the transaction with the highest ID for this account and date
            const maxIdTransaction = accountTransactions.reduce((max, t) => t.id > max.id ? t : max);
            lastKnownBalances[accountKey] = maxIdTransaction.closing_balance;
          }
          // Add the most recent known balance for this account
          closingBalanceForDate += lastKnownBalances[accountKey] || 0;
        });
  
        return closingBalanceForDate;
      });
  
      const creditAmounts = dates.map(date =>
        groupedData[date].reduce((sum, item) => sum + item.credit_amount, 0)
      );
      const debitAmounts = dates.map(date =>
        groupedData[date].reduce((sum, item) => sum + item.debit_amount, 0)
      );
      const netBalances = dates.map((date, index) =>
        index === 0 ? closingBalances[0] : closingBalances[index] - closingBalances[index - 1]
      );
  
      return {
        x: dates,
        creditAmounts,
        debitAmounts,
        netBalances,
        closingBalances
      };
    };
  }, [timeBasis]);
  
  

  const updateBarChart = (data) => {
    const categories = [...new Set(data.map(item => item[pieChartBasis]))];
    const creditAmounts = categories.map(category =>
      data.filter(item => item[pieChartBasis] === category).reduce((sum, item) => sum + item.credit_amount, 0)
    );
    const debitAmounts = categories.map(category =>
      data.filter(item => item[pieChartBasis] === category).reduce((sum, item) => sum + item.debit_amount, 0)
    );
    const netBalances = categories.map(category =>
      data.filter(item => item[pieChartBasis] === category).reduce((sum, item) => sum + item.credit_amount - item.debit_amount, 0)
    );

    const sortKey = visibleLines.credit ? 'credit' :
      visibleLines.debit ? 'debit' :
      'net';

    const sortedCategories = categories.map((category, i) => ({
      category,
      credit: creditAmounts[i],
      debit: debitAmounts[i],
      net: netBalances[i]
    })).sort((a, b) => b[sortKey] - a[sortKey]);

    return {
      x: sortedCategories.map(item => item.category),
      creditAmounts: sortedCategories.map(item => item.credit),
      debitAmounts: sortedCategories.map(item => item.debit),
      netBalances: sortedCategories.map(item => item.net)
    };
  };

  const updatePieCharts = (data, basis) => {
    const top5CreditCategories = getTop5Categories(data.filter(item => item.credit_amount > 0), 'credit_amount', basis).top5;
    const top5DebitCategories = getTop5Categories(data.filter(item => item.debit_amount > 0), 'debit_amount', basis).top5;

    return {
      credit: {
        labels: top5CreditCategories.map(item => item.label),
        values: top5CreditCategories.map(item => item.value)
      },
      debit: {
        labels: top5DebitCategories.map(item => item.label),
        values: top5DebitCategories.map(item => item.value)
      }
    };
  };

  const getTop5Categories = (data, amountType, basis) => {
    const categoryTotals = data.reduce((acc, item) => {
      const category = item[basis];
      acc[category] = (acc[category] || 0) + item[amountType];
      return acc;
    }, {});

    const sortedCategories = Object.keys(categoryTotals).map(key => ({
      label: key,
      value: categoryTotals[key],
    })).sort((a, b) => b.value - a.value);

    const top5 = sortedCategories.slice(0, 5);
    const others = sortedCategories.slice(5);

    const othersValue = others.reduce((sum, item) => sum + item.value, 0);
    top5.push({ label: 'Others', value: othersValue });

    return {
      top5,
      others,
    };
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 576);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sortedData = useCallback(() => {
    if (!sortConfig.key) return filteredData;
    return filteredData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }, [filteredData, sortConfig]);

  const bankOptions = [...new Set(originalData.map(item => item.bank_name))].map(bank => ({ value: bank, label: bank }));
  const accountOptions = [...new Set(originalData.map(item => item.account_number))].map(acc => ({ value: acc, label: acc }));
  const categoryOptions = [...new Set(originalData.map(item => item.model_classified_cat_type))].map(cat => ({ value: cat, label: cat }));
  const transactionOptions = [...new Set(originalData.map(item => item.model_classified_tr_type))].map(tr => ({ value: tr, label: tr }));

  const resetFilters = () => {
    const minDate = new Date(Math.min(...originalData.map(item => new Date(item.date))));
    const maxDate = new Date(Math.max(...originalData.map(item => new Date(item.date))));

    setFilters({
      selectedBanks: [],
      selectedAccounts: [],
      selectedCategories: [],
      selectedTransactions: [],
      dateRange: { startDate: minDate, endDate: maxDate },
    });
  };

  const handleLineToggle = (line) => {
    setVisibleLines({
      ...visibleLines,
      [line]: !visibleLines[line],
    });
  };

  // Handle clicks on charts
  const handlePieChartClick = (data) => {
    if (data && data.points && data.points.length > 0) {
      const clickedCategory = data.points[0].label;
      if (clickedCategory === 'Others') {
        // Set filter to include all "Others" categories
        setFilters((prevFilters) => ({
          ...prevFilters,
          selectedCategories: othersCategories.map(cat => ({ value: cat, label: cat }))
        }));
      } else {
        setFilters((prevFilters) => ({
          ...prevFilters,
          selectedCategories: [{ value: clickedCategory, label: clickedCategory }]
        }));
      }
    }
  };

  // const handleTimelineChartClick = (data) => {
  //   if (data && data.points && data.points.length > 0) {
  //     const clickedDate = new Date(data.points[0].x);
  //     setFilters((prevFilters) => ({
  //       ...prevFilters,
  //       dateRange: { startDate: clickedDate, endDate: clickedDate }
  //     }));
  //   }
  // };

  const handleTimelineChartClick = (data) => {
    if (data && data.points && data.points.length > 0) {
        const rawClickedDate = data.points[0].x;
        console.log('Raw clicked date value:', rawClickedDate);

        let startDate, endDate;
        
        if (timeBasis === 'Monthly') {
            const year = parseInt(rawClickedDate.split('-')[0]);
            const month = parseInt(rawClickedDate.split('-')[1]) - 1; // Month is zero-indexed
            startDate = new Date(year, month, 1, 0, 0, 0); // Start of the month
            endDate = new Date(year, month + 1, 0, 23, 59, 59); // End of the month
        } else if (timeBasis === 'Quarterly') {
            const year = parseInt(rawClickedDate.split('Q')[0]);
            const quarter = parseInt(rawClickedDate.split('Q')[1]) - 1; // Quarter is zero-indexed
            startDate = new Date(year, quarter * 3, 1, 0, 0, 0); // Start of the quarter
            endDate = new Date(year, (quarter + 1) * 3, 0, 23, 59, 59); // End of the quarter
        } else if (timeBasis === 'Yearly') {
            const year = parseInt(rawClickedDate);
            console.log('Year detected:', year);
            startDate = new Date(year, 0, 1, 0, 0, 0); // Start of the year
            endDate = new Date(year, 11, 31, 23, 59, 59); // End of the year
        } else {
            const clickedDate = new Date(rawClickedDate);
            startDate = new Date(clickedDate.setHours(0, 0, 0, 0));
            endDate = new Date(clickedDate.setHours(23, 59, 59, 999));
        }

        console.log('Start Date:', startDate);
        console.log('End Date:', endDate);

        setFilters((prevFilters) => ({
            ...prevFilters,
            dateRange: { startDate, endDate }
        }));
    }
  };


  

  const handleBarChartClick = (data) => {
    if (data && data.points && data.points.length > 0) {
      const clickedCategory = data.points[0].x;
      if (clickedCategory === 'Others') {
        // Set filter to include all "Others" categories
        setFilters((prevFilters) => ({
          ...prevFilters,
          selectedCategories: othersCategories.map(cat => ({ value: cat, label: cat }))
        }));
      } else {
        setFilters((prevFilters) => ({
          ...prevFilters,
          selectedCategories: [{ value: clickedCategory, label: clickedCategory }]
        }));
      }
    }
  };

  // Handle clicks on table rows
  const handleTableRowClick = (row) => {
    const rowDate = new Date(row.date);
    if (isNaN(rowDate)) {
      console.error('Invalid date:', row.date);
      return;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedBanks: [{ value: row.bank_name, label: row.bank_name }],
      selectedAccounts: [{ value: row.account_number, label: row.account_number }],
      selectedCategories: [{ value: row.model_classified_cat_type, label: row.model_classified_cat_type }],
      selectedTransactions: [{ value: row.model_classified_tr_type, label: row.model_classified_tr_type }],
      dateRange: { startDate: rowDate, endDate: rowDate }
    }));
  };

  // Calculate the data for the current page
  const currentData = sortedData().slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Calculate total pages
  const totalPages = Math.ceil(sortedData().length / itemsPerPage);

  // Function to handle page changes
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const downloadCSV = () => {
    const dataToDownload = filteredData.length > 0 ? filteredData : originalData;
    const csvContent = [
      ["Date", "Narration", "Ref No", "Credit Amount", "Debit Amount", "Closing Balance", "Transaction Type", "Category Type", "Account No", "Bank"],
      ...dataToDownload.map(item => [
        item.date,
        item.narration,
        item.ref_no,
        item.credit_amount,
        item.debit_amount,
        item.closing_balance,
        item.model_classified_tr_type,
        item.model_classified_cat_type,
        item.account_number,
        item.bank_name
      ])
    ].map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "financial_data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Pagination controls component
  const PaginationControls = ({ currentPage, totalPages, onPageChange }) => {
    return (
      <Pagination>
        {/* First Page Button */}
        <Pagination.First
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
        />
        
        {/* Previous Page Button */}
        <Pagination.Prev
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        
        {/* Next Page Button */}
        <Pagination.Next
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
        
        {/* Last Page Button */}
        <Pagination.Last
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
        />
      </Pagination>
    );
  };

  // function formatNumberToIndianSystem(number) {
  //   const [integerPart, decimalPart] = number.toString().split('.');
  //   const lastThreeDigits = integerPart.slice(-3);
  //   const otherDigits = integerPart.slice(0, -3);

  //   const formattedNumber = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + (otherDigits ? ',' : '') + lastThreeDigits;

  //   return decimalPart ? `${formattedNumber}.${decimalPart}` : formattedNumber;
  // }

  function formatNumberToIndianSystem(number) {
    if (number === undefined || number === null) {
        return ''; // Return an empty string or any default value you'd prefer
    }

    const [integerPart, decimalPart] = number.toString().split('.');
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);

    const formattedNumber = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + (otherDigits ? ',' : '') + lastThreeDigits;

    return decimalPart ? `${formattedNumber}.${decimalPart}` : formattedNumber;
  }

  function generateUniformTickValues(maxValue,minValue) {
    function roundToNearest(num, base) {
      if (num >= 0) {
        return Math.ceil(num / base) * base;
      } else {
        return Math.floor(num / base) * base;
      }
    }

    function determineBase(stepSize) {
      const magnitude = Math.pow(10, Math.floor(Math.log10(Math.abs(stepSize))));
      
      // Determine the base to round to based on the stepSize
      if (Math.abs(stepSize) >= 5 * magnitude) {
        return magnitude; // Round to nearest 10, 100, 1000, etc.
      } else if (Math.abs(stepSize) >= 2 * magnitude) {
        return 0.5 * magnitude; // Round to nearest 5, 50, 500, etc.
      } else {
        return 0.2 * magnitude; // Round to nearest 2, 20, 200, etc.
      }
    }
    const stepSize = Math.ceil((maxValue-minValue)/5);  // Define the step size as 5,00,000
    const base = determineBase(stepSize);
    const roundedStepSize = roundToNearest(stepSize, base);
    
    
    // const roundedMinValue = roundToNearest(minValue, base2);
    var roundedMinValue = 0;
    const tickValues = [];
    if(minValue >= 0){
      roundedMinValue = 0;
    }
    else{
      const base2 = determineBase(minValue);
      roundedMinValue = roundToNearest(minValue, base2);
    }
    
    const maxAdjustedValue = Math.ceil(maxValue / roundedStepSize) * roundedStepSize ;
    // console.log(roundedMinValue);
    // console.log(maxAdjustedValue);
    
    
    for (let i = roundedMinValue; i <= maxAdjustedValue; i += roundedStepSize) {
        tickValues.push(i);
    }

    return tickValues;
  }

  function generateTickText(tickValues) {
    return tickValues.map(value => {
      const absValue = Math.abs(value);
      
      if (absValue >= 10000000) {
        return (value / 10000000).toFixed(1) + 'cr';
      } else if (absValue >= 100000) {
        return (value / 100000).toFixed(1) + 'lac';
      } else if (absValue >= 1000) {
        return (value / 1000).toFixed(1) + 'k';
      } else {
        return value.toString();
      }
    });
  }



  


  return (
    <div className="analysis-section">
      <div className="reset-filters">
        <button onClick={resetFilters}>Reset Filters</button>
      </div>

      <div className="filters-row">
        <Select
          isMulti
          options={bankOptions}
          placeholder="Select Banks"
          onChange={selected => setFilters({ ...filters, selectedBanks: selected })}
        />
        <Select
          isMulti
          options={accountOptions}
          placeholder="Select Accounts"
          onChange={selected => setFilters({ ...filters, selectedAccounts: selected })}
        />
        <Select
          isMulti
          options={categoryOptions}
          placeholder="Select Categories"
          onChange={selected => setFilters({ ...filters, selectedCategories: selected })}
        />
        <Select
          isMulti
          options={transactionOptions}
          placeholder="Select Transactions"
          onChange={selected => setFilters({ ...filters, selectedTransactions: selected })}
        />
        <DatePicker
          selected={filters.dateRange.startDate}
          onChange={(date) => setFilters({ ...filters, dateRange: { ...filters.dateRange, startDate: date } })}
          selectsStart
          startDate={filters.dateRange.startDate}
          endDate={filters.dateRange.endDate}
          placeholderText="Start Date"
        />
        <DatePicker
          selected={filters.dateRange.endDate}
          onChange={(date) => setFilters({ ...filters, dateRange: { ...filters.dateRange, endDate: date } })}
          selectsEnd
          startDate={filters.dateRange.startDate}
          endDate={filters.dateRange.endDate}
          placeholderText="End Date"
        />
      </div>

      <div className="scorecards">
        <div className="scorecard">
          <p3>Total Credit Amount</p3>
          <p>{formatNumberToIndianSystem(scorecards.totalCreditAmount)}</p>
        </div>
        <div className="scorecard">
          <p3>Total Debit Amount</p3>
          <p>{formatNumberToIndianSystem(scorecards.totalDebitAmount)}</p>
        </div>
        <div className="scorecard">
          <p3>Total Transaction Count</p3>
          <p>{formatNumberToIndianSystem(scorecards.totalTransactionCount)}</p>
        </div>
        <div className="scorecard">
          <p3>Total Bank Count</p3>
          <p>{formatNumberToIndianSystem(scorecards.totalBankCount)}</p>          
        </div>
        <div className="scorecard">
          <p3>Net Balance</p3>
          <p>{formatNumberToIndianSystem(scorecards.netBalance)}</p>
        </div>
        <div className="scorecard">
          <p3>Closing Balance</p3>
          <p>{formatNumberToIndianSystem(scorecards.closingBalance)}</p>
        </div>
      </div>

      <div className="time-basis-chart-toggle-container">
        <div className="time-basis-selection">
          <label>
            <input
              type="radio"
              value="Daily"
              checked={timeBasis === 'Daily'}
              onChange={() => setTimeBasis('Daily')}
            />
            Daily
          </label>
          <label>
            <input
              type="radio"
              value="Monthly"
              checked={timeBasis === 'Monthly'}
              onChange={() => setTimeBasis('Monthly')}
            />
            Monthly
          </label>
          <label>
            <input
              type="radio"
              value="Quarterly"
              checked={timeBasis === 'Quarterly'}
              onChange={() => setTimeBasis('Quarterly')}
            />
            Quarterly
          </label>
          <label>
            <input
              type="radio"
              value="Yearly"
              checked={timeBasis === 'Yearly'}
              onChange={() => setTimeBasis('Yearly')}
            />
            Yearly
          </label>
        </div>

        <div className="line-chart-toggle">
          <input
            type="checkbox"
            checked={visibleLines.credit}
            onChange={() => handleLineToggle('credit')}
          />
          Credit Amount
          <input
            type="checkbox"
            checked={visibleLines.debit}
            onChange={() => handleLineToggle('debit')}
          />
          Debit Amount
          <input
            type="checkbox"
            checked={visibleLines.net}
            onChange={() => handleLineToggle('net')}
          />
          Net Balance
          <input
            type="checkbox"
            checked={visibleLines.closing}
            onChange={() => handleLineToggle('closing')}
          />
          Closing Balance
        </div>
      </div>


      <div className="charts">
        <div>
          {/* <h3>Credit and Debit Amount Over Time</h3> */}
          <Plot
            data={[
              visibleLines.credit && {
                x: updateLineChart(filteredData,tempdata).x,
                y: updateLineChart(filteredData,tempdata).creditAmounts,
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: 'blue' },
                name: 'Credit Amount',
                hovertemplate: 'Credit Amount: ₹%{customdata}<extra></extra>',
                customdata: updateLineChart(filteredData,tempdata).creditAmounts.map(value => formatNumberToIndianSystem(value)), // Passing formatted numbers
              },
              visibleLines.debit && {
                x: updateLineChart(filteredData,tempdata).x,
                y: updateLineChart(filteredData,tempdata).debitAmounts,
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: 'purple' },
                name: 'Debit Amount',
                hovertemplate: 'Debit Amount: ₹%{customdata}<extra></extra>',
                customdata: updateLineChart(filteredData,tempdata).debitAmounts.map(value => formatNumberToIndianSystem(value)), // Passing formatted numbers
              },
              visibleLines.net && {
                x: updateLineChart(filteredData,tempdata).x,
                y: updateLineChart(filteredData,tempdata).netBalances,
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: 'red' },
                name: 'Net Balance',
                yaxis: 'y2',
                hovertemplate: 'Net Balance: ₹%{customdata}<extra></extra>',
                customdata: updateLineChart(filteredData,tempdata).netBalances.map(value => formatNumberToIndianSystem(value)), // Passing formatted numbers

              },
              visibleLines.closing && {
                x: updateLineChart(filteredData,tempdata).x,
                y: updateLineChart(filteredData,tempdata).closingBalances,
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: 'green' },
                name: 'Closing Balance',
                hovertemplate: 'Closing Balance: ₹%{customdata}<extra></extra>',
                customdata: updateLineChart(filteredData,tempdata).closingBalances.map(value => formatNumberToIndianSystem(value)), // Passing formatted numbers
                
              }
            ].filter(Boolean)}
            layout={{
              autosize: true,
              xaxis: { title: 'Date', automargin: true, autorange: timeBasis === 'Quarterly' ? 'non-reversed' : true },
              yaxis: { 
                title: 'Amount', 
                automargin: true,
                tickvals: generateUniformTickValues(Math.max(...updateLineChart(filteredData,tempdata).creditAmounts, ...updateLineChart(filteredData,tempdata).debitAmounts, ...updateLineChart(filteredData,tempdata).closingBalances),0),
                ticktext: generateTickText(generateUniformTickValues(Math.max(...updateLineChart(filteredData,tempdata).creditAmounts, ...updateLineChart(filteredData,tempdata).debitAmounts, ...updateLineChart(filteredData,tempdata).closingBalances),0)).map(value => `₹${formatNumberToIndianSystem(value)}`),
              },
              yaxis2: {
                title: 'Net Balance',
                overlaying: 'y',
                side: 'right',
                automargin: true,
                tickvals: generateUniformTickValues(Math.max(...updateLineChart(filteredData,tempdata).netBalances),Math.min(...updateLineChart(filteredData,tempdata).netBalances)),
                ticktext: generateTickText(generateUniformTickValues(Math.max(...updateLineChart(filteredData,tempdata).netBalances),Math.min(...updateLineChart(filteredData,tempdata).netBalances))).map(value => `₹${formatNumberToIndianSystem(value)}`),
              },
              hovermode: 'x unified',
              showlegend: true,
              margin: { l: 0, r: 0, b: 0, t: 50, pad: 0 },
              legend: {
                orientation: 'h',
                yanchor: 'top',
                y: 1.02,
                xanchor: 'center',
                x: 0.5,
              },
            }}
            config={{
              responsive: false,
              displayModeBar: true,
            }}
            onClick={handleTimelineChartClick}
            useResizeHandler
            style={{ width: '100%', height: '100%' }}
          />
        </div>

        <div>
          <h3>{pieChartBasis === 'model_classified_cat_type' ? 'Category-wise' : 'Transaction-wise'} Credit and Debit Amount</h3>
          <Plot
            data={[
              visibleLines.credit && {
                x: updateBarChart(filteredData).x,
                y: updateBarChart(filteredData).creditAmounts,
                type: 'bar',
                name: 'Credit Amount',
                marker: { color: 'blue' },
                hovertemplate: 'Credit Amount: ₹%{customdata}<extra></extra>',
                customdata: updateBarChart(filteredData).creditAmounts.map(value => formatNumberToIndianSystem(value)),
              },
              visibleLines.debit && {
                x: updateBarChart(filteredData).x,
                y: updateBarChart(filteredData).debitAmounts,
                type: 'bar',
                name: 'Debit Amount',
                marker: { color: 'purple' },
                hovertemplate: 'Debit Amount: ₹%{customdata}<extra></extra>',
                customdata: updateBarChart(filteredData).debitAmounts.map(value => formatNumberToIndianSystem(value)),
              },
              visibleLines.net && {
                x: updateBarChart(filteredData).x,
                y: updateBarChart(filteredData).netBalances,
                type: 'bar',
                name: 'Net Balance',
                marker: { color: 'red' },
                hovertemplate: 'Net Balance: ₹%{customdata}<extra></extra>',
                customdata: updateBarChart(filteredData).netBalances.map(value => formatNumberToIndianSystem(value)),
              }
            ].filter(Boolean)}
            layout={{
              autosize: true,
              xaxis: { title: pieChartBasis === 'model_classified_cat_type' ? 'Category' : 'Transaction Type', automargin: true },
              yaxis: { title: 'Amount', automargin: true,
                tickvals: generateUniformTickValues(Math.max(...updateBarChart(filteredData).creditAmounts, ...updateBarChart(filteredData).debitAmounts, ...updateBarChart(filteredData).netBalances),Math.min(...updateBarChart(filteredData).creditAmounts, ...updateBarChart(filteredData).debitAmounts, ...updateBarChart(filteredData).netBalances)),
                ticktext: generateTickText(generateUniformTickValues(Math.max(...updateBarChart(filteredData).creditAmounts, ...updateBarChart(filteredData).debitAmounts, ...updateBarChart(filteredData).netBalances),Math.min(...updateBarChart(filteredData).creditAmounts, ...updateBarChart(filteredData).debitAmounts, ...updateBarChart(filteredData).netBalances))).map(value => `₹${formatNumberToIndianSystem(value)}`),
               },
              hovermode: 'x unified',
              showlegend: true,
              margin: { l: 0, r: 0, b: 0, t: 50, pad: 4 },
              legend: {
                orientation: 'h',
                yanchor: 'top',
                y: 1.02,
                xanchor: 'center',
                x: 0.5,
              },
            }}
            config={{
              responsive: false,
              displayModeBar: true,
            }}
            onClick={handleBarChartClick}
            useResizeHandler
            style={{ width: '100%', height: '100%' }}
          />
        </div>

        <div className="filters-row">
          <Select
            options={[
              { value: 'model_classified_cat_type', label: 'Category Type' },
              { value: 'model_classified_tr_type', label: 'Transaction Type' },
            ]}
            defaultValue={{ value: 'model_classified_cat_type', label: 'Category Type' }}
            onChange={(selected) => setPieChartBasis(selected.value)}
            placeholder="Select Pie Chart Basis"
          />
        </div>

        <div className="pie-charts">
          <div className="pie-chart">
            <h3>Credit Amount by {pieChartBasis === 'model_classified_cat_type' ? 'Category' : 'Transaction Type'}</h3>
            <Plot
              data={[
                {
                  labels: updatePieCharts(filteredData, pieChartBasis).credit.labels,
                  values: updatePieCharts(filteredData, pieChartBasis).credit.values,
                  type: 'pie',
                  name: 'Credit Amount',
                  hovertemplate: '%{label}: ₹%{customdata}<extra></extra>',
                  customdata: updatePieCharts(filteredData, pieChartBasis).credit.values.map(value => formatNumberToIndianSystem(value)),
                },
              ]}
              layout={{
                autosize: true,
                margin: { l: 0, r: 0, b: 0, t: 0, pad: 0 },
                legend: {
                  orientation: 'h',
                  yanchor: 'top',
                  y: 0,
                  xanchor: 'center',
                  x: 0.5,
                },
              }}
              config={{
                responsive: true,
                displayModeBar: false,
              }}
              onClick={handlePieChartClick}
              useResizeHandler
              style={{ width: '100%', height: '90%' }}
            />
          </div>

          <div className="pie-chart">
            <h3>Debit Amount by {pieChartBasis === 'model_classified_cat_type' ? 'Category' : 'Transaction Type'}</h3>
            <Plot
              data={[
                {
                  labels: updatePieCharts(filteredData, pieChartBasis).debit.labels,
                  values: updatePieCharts(filteredData, pieChartBasis).debit.values,
                  type: 'pie',
                  name: 'Debit Amount',
                  hovertemplate: '%{label}: ₹%{customdata}<extra></extra>',
                  customdata: updatePieCharts(filteredData, pieChartBasis).debit.values.map(value => formatNumberToIndianSystem(value)), 
                },
              ]}
              layout={{
                autosize: true,
                margin: { l: 0, r: 0, b: 0, t: 0, pad: 0 },
                legend: {
                  orientation: 'h',
                  yanchor: 'top',
                  y: 0,
                  xanchor: 'center',
                  x: 0.5,
                },
              }}
              config={{
                responsive: true,
                displayModeBar: false,
              }}
              onClick={handlePieChartClick}
              useResizeHandler
              style={{ width: '100%', height: '90%' }}
            />
          </div>
        </div>
      </div>

      <div className="others-tables">
        <div className="credit-others-table">
          <h3>Credit - Others</h3>
          <PaginationProvider
            pagination={paginationFactory({ ...paginationOptions, totalSize: creditOthers.length })}
          >
            {
              ({
                paginationProps,
                paginationTableProps
              }) => (
                <div>
                  <BootstrapTable
                    keyField="label"
                    data={creditOthers}
                    columns={[
                      { dataField: 'label', text: pieChartBasis === 'model_classified_cat_type' ? 'Category Type' : 'Transaction Type', sort: true, headerClasses: 'sortable-column' },
                      { dataField: 'value', text: 'Credit Amount', formatter: (cell) => formatNumberToIndianSystem(cell), sort: true, headerClasses: 'sortable-column' },
                      {
                        dataField: 'percentage', text: 'Credit %', sort: true, headerClasses: 'sortable-column', formatter: (cell, row) =>
                          ((row.value / scorecards.totalCreditAmount) * 100).toFixed(2)
                      }
                    ]}
                    {...paginationTableProps}
                    sort={{ dataField: 'value', order: 'desc' }}
                    classes="table-center-align"
                    rowEvents={{ onClick: (e, row, rowIndex) => handleTableRowClick(row) }}
                  />
                  <PaginationListStandalone {...paginationProps} />
                </div>
              )
            }
          </PaginationProvider>
        </div>

        <div className="debit-others-table">
          <h3>Debit - Others</h3>
          <PaginationProvider
            pagination={paginationFactory({ ...paginationOptions, totalSize: debitOthers.length })}
          >
            {
              ({
                paginationProps,
                paginationTableProps
              }) => (
                <div>
                  <BootstrapTable
                    keyField="label"
                    data={debitOthers}
                    columns={[
                      { dataField: 'label', text: pieChartBasis === 'model_classified_cat_type' ? 'Category Type' : 'Transaction Type', sort: true, headerClasses: 'sortable-column' },
                      { dataField: 'value', text: 'Debit Amount', formatter: (cell) => formatNumberToIndianSystem(cell), sort: true, headerClasses: 'sortable-column' },
                      {
                        dataField: 'percentage', text: 'Debit %', sort: true, headerClasses: 'sortable-column', formatter: (cell, row) =>
                          ((row.value / scorecards.totalDebitAmount) * 100).toFixed(2)
                      }
                    ]}
                    {...paginationTableProps}
                    sort={{ dataField: 'value', order: 'desc' }}
                    classes="table-center-align"
                    rowEvents={{ onClick: (e, row, rowIndex) => handleTableRowClick(row) }}
                  />
                  <PaginationListStandalone {...paginationProps} />
                </div>
              )
            }
          </PaginationProvider>
        </div>
      </div>

      {isSmallScreen ? (
        <div className="card-view">
          {currentData.length > 0 ? (
            currentData.map((item, index) => (
              <div key={item.id} className="data-card">
                <h3>S.No {`${(currentPage - 1) * itemsPerPage + index + 1} | Acc No: ${item.account_number}`}</h3>
                <p>Narration: {item.narration}</p>
                <p>{item.date}  {` |${item.credit_amount > 0 ? '+' : '-'}${item.credit_amount > 0 ? formatNumberToIndianSystem(item.credit_amount) : formatNumberToIndianSystem(item.debit_amount)} | ${item.model_classified_tr_type} | ${item.model_classified_cat_type}`}</p>
                <p>Closing Balance: {formatNumberToIndianSystem(item.closing_balance)}</p>
              </div>
            ))
          ) : (
            <div>No data available for this page.</div>
          )}
          {/* Pagination Controls */}
          <div className="pagination-controls">
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        <div className="scrollable-table-container">
          <PaginationProvider
            pagination={paginationFactory({ ...paginationOptions2, totalSize: sortedData().length })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <BootstrapTable
                  keyField="id"
                  data={sortedData()}
                  columns={[
                    { dataField: 'sno', text: 'S.No', formatter: (cell, row, rowIndex) => (paginationProps.page - 1) * paginationProps.sizePerPage + rowIndex + 1, sort: true, headerClasses: 'sortable-column' },
                    { dataField: 'date', text: 'Date', sort: true, headerClasses: 'sortable-column' },
                    { dataField: 'narration', text: 'Narration', sort: true, headerClasses: 'sortable-column', formatter: (cell) => (
                      <OverlayTrigger placement="top" overlay={<Tooltip>{cell}</Tooltip>}>
                        <span>{cell}</span>
                      </OverlayTrigger>
                    )},
                    { dataField: 'ref_no', text: 'Ref No', sort: true, headerClasses: 'sortable-column' },
                    { dataField: 'credit_amount', text: 'Credit Amount', formatter: (cell) => formatNumberToIndianSystem(cell), sort: true, headerClasses: 'sortable-column' },
                    { dataField: 'debit_amount', text: 'Debit Amount', formatter: (cell) => formatNumberToIndianSystem(cell), sort: true, headerClasses: 'sortable-column' },
                    { dataField: 'closing_balance', text: 'Closing Balance', formatter: (cell) => formatNumberToIndianSystem(cell), sort: true, headerClasses: 'sortable-column' },
                    { dataField: 'model_classified_tr_type', text: 'Transaction Type', sort: true, headerClasses: 'sortable-column' },
                    { dataField: 'model_classified_cat_type', text: 'Category Type', sort: true, headerClasses: 'sortable-column' },
                    { dataField: 'account_number', text: 'Account No', sort: true, headerClasses: 'sortable-column' },
                    { dataField: 'bank_name', text: 'Bank', sort: true, headerClasses: 'sortable-column' },
                  ]}
                  {...paginationTableProps}
                  classes="table-center-align"
                  wrapperClasses="table-responsive"
                  rowEvents={{ onClick: (e, row, rowIndex) => handleTableRowClick(row) }}
                />
                <PaginationListStandalone {...paginationProps} />
              </div>
            )}
          </PaginationProvider>
        </div>
      )}

      {/* Footer Summary */}
      <div className="footer-summary">
        <p>Summary: Total Transactions: {formatNumberToIndianSystem(scorecards.totalTransactionCount)}, Total Credit Amount: {formatNumberToIndianSystem(scorecards.totalCreditAmount)}, Total Debit Amount: {formatNumberToIndianSystem(scorecards.totalDebitAmount)}</p>
      </div>

      {/* Download Raw Data Button */}
      <div className="download-button">
        <button onClick={downloadCSV}>Download Raw Data</button>
      </div>
    </div>
  );
};

export default AnalysisSection;
