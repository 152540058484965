import React from 'react';

const LoadingDots = () => {
  return (
    <div id="loadingDots">
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </div>
  );
};

export default LoadingDots;
