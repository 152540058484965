import React from 'react';

const MessageBox = ({ message, messageType }) => {
  const messageStyle = messageType === 'error' ? { color: 'red' } : { color: 'green' };

  return (
    <div id="messageBox" style={messageStyle}>
      {message}
    </div>
  );
};

export default MessageBox;
