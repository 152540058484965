import React, { useEffect, useState, useCallback,useRef } from 'react';
import { Table, Button, Row, Col, Container, Form, Modal } from 'react-bootstrap';
import { useTable, usePagination, useSortBy } from 'react-table';
import ValidateButton from './ValidateButton';
import './OutputSection.css';
import { findSimilarTransactions } from './utils';

const OutputSection = ({ showMessage, loggedInEmail }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userValidatedData, setUserValidatedData] = useState({});
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    narrationFilter: '',
    narrationText: '',
    amountFilter: '',
    amountValue: '',
    categoryTypeFilter: '',
    transactionTypeFilter: '',
    bankNameFilter: '',
    accountNumberFilter: ''
  });
  const [currentUpdateField, setCurrentUpdateField] = useState('');
  const [currentUpdateValue, setCurrentUpdateValue] = useState('');
  const [similarTransactions, setSimilarTransactions] = useState([]);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);
  const [triggeringTransaction, setTriggeringTransaction] = useState(null);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [sortBy, setSortBy] = useState([]);

  // Use a ref to store the table instance
  const tableInstanceRef = useRef(null);

  const fetchAndCategorizeData = useCallback(async () => {
    try {
      const response = await fetch('https://api.myexpenditure.com/api3/get_category_mapped', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: loggedInEmail })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch categorized data');
      }
      const data = await response.json();
      setData(data);
      setFilteredData(data);
      setLoading(false);
    } catch (error) {
      showMessage('An error occurred while fetching data.', true);
    }
  }, [loggedInEmail, showMessage]);

  useEffect(() => {
    fetchAndCategorizeData();
  }, [fetchAndCategorizeData]);

  const applyFilters = () => {
    let filtered = data;

    if (filters.startDate) {
      filtered = filtered.filter(item => new Date(item.date) >= new Date(filters.startDate));
    }

    if (filters.endDate) {
      filtered = filtered.filter(item => new Date(item.date) <= new Date(filters.endDate));
    }

    if (filters.narrationFilter && filters.narrationText) {
      if (filters.narrationFilter === 'contains') {
        filtered = filtered.filter(item => item.narration.includes(filters.narrationText));
      } else if (filters.narrationFilter === 'like') {
        filtered = filtered.filter(item => item.narration.toLowerCase().includes(filters.narrationText.toLowerCase()));
      } else if (filters.narrationFilter === 'in') {
        filtered = filtered.filter(item => item.narration.split(' ').includes(filters.narrationText));
      }
    }

    if (filters.amountFilter && filters.amountValue) {
      const amountValue = parseFloat(filters.amountValue);
      if (filters.amountFilter === 'greaterThan') {
        filtered = filtered.filter(item => parseFloat(item.debit_amount) > amountValue || parseFloat(item.credit_amount) > amountValue);
      } else if (filters.amountFilter === 'lessThan') {
        filtered = filtered.filter(item => parseFloat(item.debit_amount) < amountValue || parseFloat(item.credit_amount) < amountValue);
      } else if (filters.amountFilter === 'equalTo') {
        filtered = filtered.filter(item => parseFloat(item.debit_amount) === amountValue || parseFloat(item.credit_amount) === amountValue);
      }
    }

    if (filters.categoryTypeFilter) {
      filtered = filtered.filter(item => item.model_classified_cat_type === filters.categoryTypeFilter);
    }

    if (filters.transactionTypeFilter) {
      filtered = filtered.filter(item => item.model_classified_tr_type === filters.transactionTypeFilter);
    }

    if (filters.bankNameFilter) {
      filtered = filtered.filter(item => item.bank_name === filters.bankNameFilter);
    }

    if (filters.accountNumberFilter) {
      filtered = filtered.filter(item => item.account_number === filters.accountNumberFilter);
    }

    setFilteredData(filtered);
  };

  const resetFilters = () => {
    setFilters({
      startDate: '',
      endDate: '',
      narrationFilter: '',
      narrationText: '',
      amountFilter: '',
      amountValue: '',
      categoryTypeFilter: '',
      transactionTypeFilter: '',
      bankNameFilter: '',
      accountNumberFilter: ''
    });
    setFilteredData(data);
  };

  

  const handleUserValidatedChange = useCallback((transactionId, field, value) => {
    console.log('handleUserValidatedChange called:', { transactionId, field, value });
  
    // Update userValidatedData
    setUserValidatedData(prevState => ({
      ...prevState,
      [transactionId]: {
        ...prevState[transactionId],
        [field]: value
      }
    }));
  
    // Update main data and filtered data
    const updateData = (prevData) => prevData.map(item => 
      item.id === transactionId ? { ...item, [field]: value } : item
    );
    
    setData(updateData);
    setFilteredData(updateData);
  
    // Find the updated transaction in the filtered data
    const updatedTransaction = data.find(item => item.id === transactionId);
  
    if (updatedTransaction) {
      setTriggeringTransaction(updatedTransaction);
      const similar = findSimilarTransactions(data, updatedTransaction, field);
      if (similar.length > 0) {
        // Include the triggering transaction if it's not already in the list
        const allSimilar = [updatedTransaction, ...similar.filter(t => t.id !== updatedTransaction.id)];
        setSimilarTransactions(allSimilar);
        setCurrentUpdateField(field);
        setCurrentUpdateValue(value);
        setShowReviewModal(true);
      }
    }
  }, [data, findSimilarTransactions]);

  const handleReviewAndUpdateAll = () => {
    setShowReviewModal(false);
    setReviewMode(true);
    setFilteredData(similarTransactions);
  };

  const handleBulkUpdate = useCallback(() => {
    const updateData = (prevData) => prevData.map(item => 
      similarTransactions.some(similar => similar.id === item.id)
        ? { ...item, [currentUpdateField]: currentUpdateValue }
        : item
    );

    setData(updateData);
    setFilteredData(updateData);
  
    setUserValidatedData(prevState => {
      const newState = { ...prevState };
      similarTransactions.forEach(transaction => {
        newState[transaction.id] = {
          ...newState[transaction.id],
          [currentUpdateField]: currentUpdateValue
        };
      });
      return newState;
    });
  
    setReviewMode(false);
    setShowReviewModal(false);
    setTriggeringTransaction(null);
  }, [similarTransactions, currentUpdateField, currentUpdateValue]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'Narration',
        accessor: 'narration'
      },
      {
        Header: 'Debit Amount',
        accessor: 'debit_amount'
      },
      {
        Header: 'Credit Amount',
        accessor: 'credit_amount'
      },
      {
        Header: 'Closing Balance',
        accessor: 'closing_balance'
      },
      {
        Header: 'Bank Name',
        accessor: 'bank_name'
      },
      {
        Header: 'Account Number',
        accessor: 'account_number'
      },
      {
        Header: 'Transaction Type',
        accessor: 'model_classified_tr_type'
      },
      {
        Header: 'Category Type',
        accessor: 'model_classified_cat_type'
      },
      {
        Header: 'User Validated Tr Type',
        accessor: 'userValidatedTrType',
        id: 'userValidatedTrType'
      },
      {
        Header: 'User Validated Cat Type',
        accessor: 'userValidatedCatType',
        id: 'userValidatedCatType'
      },
    ],
    [userValidatedData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize: setReactTablePageSize,
    state: { pageIndex: reactTablePageIndex, pageSize: reactTablePageSize, sortBy: reactTableSortBy }
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex, pageSize, sortBy },
      manualPagination: false,
      manualSortBy: false,
      pageCount: Math.ceil(filteredData.length / pageSize),
      autoResetPage: false,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  );
  // Store the table instance in the ref
  tableInstanceRef.current = {
    pageIndex: reactTablePageIndex,
    pageSize: reactTablePageSize,
    sortBy: reactTableSortBy
  };

  // Update our state when the table state changes
  useEffect(() => {
    setPageIndex(reactTablePageIndex);
    setPageSize(reactTablePageSize);
    setSortBy(reactTableSortBy);
  }, [reactTablePageIndex, reactTablePageSize, reactTableSortBy]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ margin: '0 -6%' }}>
      <Row className="align-items-center mb-3">
        <Col sm={6} md={12}><h2>Categorized Data</h2></Col>
        <Col className="text-right">
          <ValidateButton data={data} userValidatedData={userValidatedData} loggedInEmail={loggedInEmail} showMessage={showMessage} />
        </Col>
      </Row>
      <Container fluid className="mb-4">
        <Row className="justify-content-center">
          <Col sm={12} md={12}>
            <Row className="align-items-center mb-4">
              <Col sm={6} md={2}>
                <Form.Group controlId="startDate">
                  <Form.Label>Start Date:</Form.Label>
                  <Form.Control
                    type="date"
                    value={filters.startDate}
                    onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={2}>
                <Form.Group controlId="endDate">
                  <Form.Label>End Date:</Form.Label>
                  <Form.Control
                    type="date"
                    value={filters.endDate}
                    onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={2}>
                <Form.Group controlId="narrationFilter">
                  <Form.Label>Narration:</Form.Label>
                  <Form.Control
                    as="select"
                    value={filters.narrationFilter}
                    onChange={(e) => setFilters({ ...filters, narrationFilter: e.target.value })}
                  >
                    <option value="">Select</option>
                    <option value="contains">Contains</option>
                    <option value="like">Like</option>
                    <option value="in">In</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={6} md={2}>
                <Form.Group controlId="narrationText">
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control
                    type="text"
                    value={filters.narrationText}
                    onChange={(e) => setFilters({ ...filters, narrationText: e.target.value })}
                    placeholder="Narration Text"
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={2}>
                <Form.Group controlId="amountFilter">
                  <Form.Label>Amount:</Form.Label>
                  <Form.Control
                    as="select"
                    value={filters.amountFilter}
                    onChange={(e) => setFilters({ ...filters, amountFilter: e.target.value })}
                  >
                    <option value="">Select</option>
                    <option value="greaterThan">Greater Than</option>
                    <option value="lessThan">Less Than</option>
                    <option value="equalTo">Equal To</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={6} md={2}>
                <Form.Group controlId="amountValue">
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control
                    type="number"
                    value={filters.amountValue}
                    onChange={(e) => setFilters({ ...filters, amountValue: e.target.value })}
                    placeholder="Amount Value"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center mb-4">
              <Col sm={6} md={2}>
                <Form.Group controlId="categoryTypeFilter">
                  <Form.Label>Category Type:</Form.Label>
                  <Form.Control
                    as="select"
                    value={filters.categoryTypeFilter}
                    onChange={(e) => setFilters({ ...filters, categoryTypeFilter: e.target.value })}
                  >
                    <option value="">Select</option>
                    {Array.from(new Set(data.map(item => item.model_classified_cat_type))).map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={6} md={2}>
                <Form.Group controlId="transactionTypeFilter">
                  <Form.Label>Transaction Type:</Form.Label>
                  <Form.Control
                    as="select"
                    value={filters.transactionTypeFilter}
                    onChange={(e) => setFilters({ ...filters, transactionTypeFilter: e.target.value })}
                  >
                    <option value="">Select</option>
                    {Array.from(new Set(data.map(item => item.model_classified_tr_type))).map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={6} md={2}>
                <Form.Group controlId="bankNameFilter">
                  <Form.Label>Bank Name:</Form.Label>
                  <Form.Control
                    as="select"
                    value={filters.bankNameFilter}
                    onChange={(e) => setFilters({ ...filters, bankNameFilter: e.target.value })}
                  >
                    <option value="">Select</option>
                    {Array.from(new Set(data.map(item => item.bank_name))).map((bank, index) => (
                      <option key={index} value={bank}>
                        {bank}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={6} md={2}>
                <Form.Group controlId="accountNumberFilter">
                  <Form.Label>Account Number:</Form.Label>
                  <Form.Control
                    as="select"
                    value={filters.accountNumberFilter}
                    onChange={(e) => setFilters({ ...filters, accountNumberFilter: e.target.value })}
                  >
                    <option value="">Select</option>
                    {Array.from(new Set(data.map(item => item.account_number))).map((account, index) => (
                      <option key={index} value={account}>
                        {account}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={6} md={2}>
                <Button variant="primary" onClick={applyFilters} className="mt-4 w-100">
                  Apply Filters
                </Button>
              </Col>
              <Col sm={6} md={2}>
                <Button variant="secondary" onClick={resetFilters} className="mt-4 w-100">
                  Reset Filters
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Table striped bordered hover className="mt-4" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} id="outputTable">
          {page.map((row) => {
            prepareRow(row);
            const transactionId = row.original.id; // Assume each transaction has an 'id' field
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  if (cell.column.id === 'userValidatedTrType' || cell.column.id === 'userValidatedCatType') {
                    return (
                      <td {...cell.getCellProps()}>
                        <Form.Control
                          as="select"
                          value={userValidatedData[transactionId]?.[cell.column.id] || ''}
                          onChange={(e) => handleUserValidatedChange(transactionId, cell.column.id, e.target.value)}
                        >
                          <option value="">Select</option>
                          {/* Add your options here based on the column */}
                          {cell.column.id === 'userValidatedTrType' ? (
                            <>
                              <option value="P2P">P2P</option>
                              <option value="Merchant">Merchant</option>
                              <option value="Credit Card">Credit Card</option>
                              <option value="Wallet">Wallet</option>
                              <option value="ATM">ATM</option>
                            </>
                          ) : (
                            <>
                              <option value="Grocery">Grocery</option>
                              <option value="Salon & Spa">Salon & Spa</option>
                              <option value="Salary">Salary</option>
                              <option value="Rent">Rent</option>
                              <option value="Food">Food</option>
                              <option value="Credit Card">Credit Card</option>
                              <option value="Other Account">Other Account</option>
                              <option value="Utilities">Utilities</option>
                              <option value="PayTM">PayTM</option>
                              <option value="Interest">Interest</option>
                              <option value="Car or Bike Rental">Car or Bike Rental</option>
                              <option value="Loan">Loan</option>
                              <option value="Cab">Cab</option>
                              <option value="Shopping">Shopping</option>
                              <option value="Toll & Parking">Toll & Parking</option>
                              <option value="Investment">Investment</option>
                              <option value="ATM">ATM</option>
                              <option value="Flights & Stays">Flights & Stays</option>
                              <option value="Alcohol">Alcohol</option>
                              <option value="Fuel">Fuel</option>
                              <option value="Medical">Medical</option>
                              <option value="Tour & Experiences">Tour & Experiences</option>
                              <option value="Entertainment">Entertainment</option>
                              <option value="Adjustment">Adjustment</option>
                              <option value="TAX">TAX</option>
                              <option value="Poker">Poker</option>
                              <option value="International markup & TCS">International markup & TCS</option>
                              <option value="Fixed Deposit">Fixed Deposit</option>
                              <option value="Money Home">Money Home</option>
                              <option value="Vacation">Vacation</option>
                              <option value="Visa">Visa</option>
                              <option value="Gym">Gym</option>
                              <option value="Travel">Travel</option>
                              <option value="Donation">Donation</option>
                              <option value="Learning">Learning</option>
                              <option value="Startup">Startup</option>
                              <option value="Child Education">Child Education</option>
                            </>
                          )}
                        </Form.Control>
                      </td>
                    );
                  }
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="pagination text-center">
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </Button>{' '}
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </Button>{' '}
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </Button>{' '}
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </Button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px', display: 'inline-block' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setReactTablePageSize(Number(e.target.value));
          }}
          style={{ display: 'inline-block' }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <Modal show={showReviewModal} onHide={() => setShowReviewModal(false)}>
        <Modal.Header>
          <Modal.Title>Similar Transactions Found</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>We found {similarTransactions.length} similar transactions. Do you want to review and update them all?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowReviewModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleReviewAndUpdateAll}>
            Review and Update All
          </Button>
        </Modal.Footer>
      </Modal>

      {reviewMode && (
        <div className="mt-4">
          <h4>Reviewing Similar Transactions</h4>
          <Button variant="primary" onClick={handleBulkUpdate}>
            Confirm Updates
          </Button>
          <Button variant="secondary" className="ml-2" onClick={() => {
            setReviewMode(false);
            setFilteredData(data);
          }}>
            Cancel Review
          </Button>
        </div>
      )}
    </div>
  );
};

export default OutputSection;
