import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const OTPForm = ({ showMessage, email, showSetPassword, showLogin }) => {
  const [otp, setOTP] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://api.myexpenditure.com/api7/submit_otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, otp })
      });
      if (!response.ok) {
        throw new Error('Invalid OTP');
      }
      showMessage('OTP verified. Please set your new password.');
      showSetPassword();
    } catch (error) {
      showMessage('An error occurred while verifying OTP.', true);
    }
  };

  return (
    <Form className="form">
      <Form.Group controlId="formEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" value={email} readOnly />
      </Form.Group>
      <Form.Group controlId="formOTP">
        <Form.Label>OTP</Form.Label>
        <Form.Control type="text" value={otp} onChange={(e) => setOTP(e.target.value)} required />
      </Form.Group>
      <Button variant="primary" onClick={handleSubmit} style={{ marginTop: '20px' }}>
        Submit OTP
      </Button>
      <Button variant="primary" onClick={showLogin} style={{marginLeft:'20px', marginTop: '20px' }}>
        Login
      </Button>
    </Form>
  );
};

export default OTPForm;
