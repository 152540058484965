// Tokenization function
function tokenizeNarration(narration) {
    // Split on spaces and special characters
    const tokens = narration.split(/[\s\-_,\.:/]+/);
    
    // Remove empty tokens and convert to lowercase
    return tokens.filter(token => token.length > 0).map(token => token.toLowerCase());
  }
  
  // Calculate Jaccard similarity between two sets of tokens
  function calculateJaccardSimilarity(tokens1, tokens2) {
    const set1 = new Set(tokens1);
    const set2 = new Set(tokens2);
    
    const intersection = new Set([...set1].filter(x => set2.has(x)));
    const union = new Set([...set1, ...set2]);
    
    return intersection.size / union.size;
  }
  
  export const findSimilarTransactions = (transactions, currentTransaction, field) => {
    console.log('findSimilarTransactions called:', { currentTransaction, field });
  
    if (!currentTransaction || !currentTransaction.narration) {
      console.error('Invalid currentTransaction:', currentTransaction);
      return [];
    }
  
    const similarityThreshold = 0.6; // Adjust this value to control sensitivity
    const currentTokens = tokenizeNarration(currentTransaction.narration);
  
    return transactions.filter(transaction => {
      if (!transaction || transaction.id === currentTransaction.id) return false;
  
      const transactionTokens = tokenizeNarration(transaction.narration);
      const similarity = calculateJaccardSimilarity(currentTokens, transactionTokens);
  
      // Check for date pattern (if available)
      const dateMatch = transaction.date && currentTransaction.date &&
                        new Date(transaction.date).getDate() === new Date(currentTransaction.date).getDate();
  
      // Check for amount similarity (if available)
      const amountMatch = transaction.debit_amount === currentTransaction.debit_amount ||
                          transaction.credit_amount === currentTransaction.credit_amount;
  
      // Check if the specified field (if any) matches
      const fieldSimilarity = field ? 
        (transaction[field] === currentTransaction[field] || 
         (!transaction[field] && !currentTransaction[field])) : true;
  
      return similarity > similarityThreshold && 
             (dateMatch || amountMatch) && 
             fieldSimilarity;
    });
  };