import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
import './LoginForm.css';

const LoginForm = ({ showMessage, onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.myexpenditure.com/api1/verify_login_creds', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });
      if (!response.ok) {
        throw new Error('Invalid login credentials');
      }
      const data = await response.json();
      if (data.status === 1) {
        onLogin(email);
      } else {
        showMessage('Invalid login credentials. Please try again.', true);
      }
    } catch (error) {
      showMessage('An error occurred while trying to log in. Please try again.', true);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        console.log('Google login successful, tokenResponse:', tokenResponse);

        const response = await fetch('https://api.myexpenditure.com/api10/google-login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token: tokenResponse.access_token })
        });
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Failed to authenticate with the server');
        }

        const data = await response.json();
        if (data.status === 1) {
          onLogin(data.email);
        } else {
          throw new Error(data.message || 'Google login failed');
        }
      } catch (error) {
        console.error('Google login error:', error);
        showMessage(`Google login error: ${error.message}`, true);
      }
    },
    onError: (error) => {
      console.error('Google login error:', error);
      showMessage(`Google login error: ${error.error_description || error.message}`, true);
    }
  });

  return (
    <Form className="login-form" onSubmit={handleSubmit}>
      <Form.Group controlId="formEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />
      </Form.Group>
      <Form.Group controlId="formPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          required 
        />
      </Form.Group>
      <Row className="mt-3">
        <Col>
          <Button variant="primary" type="submit" className="w-100">
            Login
          </Button>
        </Col>
        <Col>
          <button 
            type="button"
            className="google-sign-in-button w-100"
            onClick={() => googleLogin()}
          >
            <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{marginRight: '8px'}}>
              <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
              <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
              <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
              <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
              <path fill="none" d="M0 0h48v48H0z"/>
            </svg>
            Google
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default LoginForm;