import React, { useState, useEffect } from 'react';
import { Card, Dropdown, Form } from 'react-bootstrap';
import { ChevronDown } from 'lucide-react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Range } from 'react-range';

const ResponsiveCard = styled(Card)`
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.h5`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #555;
`;

const SeeAllButton = styled.button`
  background: none;
  border: none;
  color: #4F46E5;
  font-weight: bold;
  cursor: pointer;
  transition: none;

  &:hover, &:focus, &:active {
    color: #4F46E5;
    background: none;
    text-decoration: none;
    outline: none;
  }

  @media (max-width: 576px) {
    margin-top: 10px;
  }
`;

const FilterRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  @media (min-width: 577px) {
    align-items: center;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  @media (min-width: 577px) {
    width: 32%;
  }

  @media (max-width: 576px) {
    width: 100%;
  }

  .dropdown-toggle {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: #f3f4f6;
    border: none;
    border-radius: 8px;
    padding: 8px 12px;
    text-align: centre;

    @media (max-width: 576px) {
      padding: 6px 10px;
      font-size: 14px;
    }
  }
`;

const TransactionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 0;
  border-bottom: 1px solid #e5e7eb;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const TransactionDetails = styled.div`
  flex: 1;
  margin-right: 10px;
  word-break: break-word;
  text-align: left;

  @media (max-width: 576px) {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }
`;

const TransactionName = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 0 0 5px 0;
`;

const TransactionDate = styled.p`
  font-size: 14px;
  color: #6B7280;
  margin: 0;
`;

const TransactionAmount = styled.div`
  text-align: right;

  @media (max-width: 576px) {
    text-align: center;
    width: 100%;
  }
`;

const Amount = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.isPositive ? '#10B981' : '#EF4444'};
  margin: 0 0 5px 0;
`;

const Category = styled.span`
  display: inline-block;
  font-size: 12px;
  color: #6B7280;
  background-color: #F3F4F6;
  padding: 2px 8px;
  border-radius: 12px;
`;

const SliderContainer = styled.div`
  width: 100%;
  padding: 20px 0;
`;

const SliderLabel = styled.span`
  font-size: 12px;
  color: #666;
`;



const RecentTransactions = ({ 
  filteredTransactions, 
  selectedTransactionType, 
  selectedTransactionBanks, 
  handleTransactionTypeSelect, 
  handleTransactionBankSelect, 
  allBanks, 
  formatCurrency,
  navigateToCategorize
}) => {
  const [sortOrder, setSortOrder] = useState('Date: Desc to Asc');
  const [displayedTransactions, setDisplayedTransactions] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 0]);

  useEffect(() => {
    if (filteredTransactions.length > 0) {
      const amounts = filteredTransactions.map(t => t.amount);
      const minAmount = Math.min(...amounts);
      const maxAmount = Math.max(...amounts);
      setPriceRange([minAmount, maxAmount]);
    }
  }, [filteredTransactions]);

  useEffect(() => {
    let filtered = filteredTransactions.filter(t => 
      t.amount >= priceRange[0] && t.amount <= priceRange[1]
    );

    switch (sortOrder) {
      case 'Amount: Low to High':
        filtered.sort((a, b) => a.amount - b.amount);
        break;
      case 'Amount: High to Low':
        filtered.sort((a, b) => b.amount - a.amount);
        break;
      case 'Date: Asc to Desc':
        filtered.sort((a, b) => new Date(a.date) - new Date(b.date));
        break;
      case 'Date: Desc to Asc':
      default:
        filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
        break;
    }
    setDisplayedTransactions(filtered.slice(0, 5));
  }, [filteredTransactions, sortOrder, priceRange]);

  return (
    <ResponsiveCard>
      <Card.Body>
        <Header>
          <Title>RECENT TRANSACTIONS</Title>
          <SeeAllButton onClick={navigateToCategorize}>SEE ALL</SeeAllButton>
        </Header>
        <FilterRow>
          <StyledDropdown>
            <Dropdown.Toggle variant="light" id="dropdown-transaction-type">
              {selectedTransactionType} <ChevronDown size={16} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleTransactionTypeSelect('All transactions')}>All transactions</Dropdown.Item>
              <Dropdown.Item onClick={() => handleTransactionTypeSelect('Credit')}>Credit</Dropdown.Item>
              <Dropdown.Item onClick={() => handleTransactionTypeSelect('Debit')}>Debit</Dropdown.Item>
            </Dropdown.Menu>
          </StyledDropdown>
          <StyledDropdown>
            <Dropdown.Toggle variant="light" id="transaction-bank-filter-dropdown">
              {selectedTransactionBanks.length === allBanks.length ? 'All banks' : `${selectedTransactionBanks.length} selected`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as="button" onClick={() => handleTransactionBankSelect('ALL BANKS')}>
                <Form.Check
                  type="checkbox"
                  checked={selectedTransactionBanks.length === allBanks.length}
                  onChange={() => {}}
                  label="ALL BANKS"
                />
              </Dropdown.Item>
              {allBanks.map((bank) => (
                <Dropdown.Item key={bank} as="button" onClick={() => handleTransactionBankSelect(bank)}>
                  <Form.Check
                    type="checkbox"
                    checked={selectedTransactionBanks.includes(bank)}
                    onChange={() => {}}
                    label={bank}
                  />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </StyledDropdown>
          <StyledDropdown>
            <Dropdown.Toggle variant="light" id="sort-order-dropdown">
              {sortOrder} <ChevronDown size={16} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setSortOrder('Amount: Low to High')}>Amount: Low to High</Dropdown.Item>
              <Dropdown.Item onClick={() => setSortOrder('Amount: High to Low')}>Amount: High to Low</Dropdown.Item>
              <Dropdown.Item onClick={() => setSortOrder('Date: Desc to Asc')}>Date: Desc to Asc</Dropdown.Item>
              <Dropdown.Item onClick={() => setSortOrder('Date: Asc to Desc')}>Date: Asc to Desc</Dropdown.Item>
            </Dropdown.Menu>
          </StyledDropdown>
        </FilterRow>
        <SliderContainer>
          <Range
            values={priceRange}
            step={100}
            min={Math.min(...filteredTransactions.map(t => t.amount))}
            max={Math.max(...filteredTransactions.map(t => t.amount))}
            onChange={(values) => setPriceRange(values)}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '6px',
                  width: '100%',
                  backgroundColor: '#ccc'
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    height: '6px',
                    width: `${props.style.width}%`,
                    backgroundColor: '#4F46E5',
                    left: `${props.style.left}%`,
                  }}
                />
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '20px',
                  width: '20px',
                  backgroundColor: '#4F46E5',
                  borderRadius: '50%',
                }}
              />
            )}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <SliderLabel>{formatCurrency(priceRange[0])}</SliderLabel>
            <SliderLabel>{formatCurrency(priceRange[1])}</SliderLabel>
          </div>
        </SliderContainer>
        {displayedTransactions.map((transaction) => (
          <TransactionItem key={transaction.id}>
            <TransactionDetails>
              <TransactionName>{transaction.description}</TransactionName>
              <TransactionDate>
                {format(new Date(transaction.date), "EEEE, do MMMM")}
              </TransactionDate>
            </TransactionDetails>
            <TransactionAmount>
              <Amount isPositive={transaction.amount > 0}>
                {transaction.amount > 0 ? '+' : '-'}{formatCurrency(Math.abs(transaction.amount))}
              </Amount>
              <Category>{transaction.category}</Category>
            </TransactionAmount>
          </TransactionItem>
        ))}
      </Card.Body>
    </ResponsiveCard>
  );
};

export default RecentTransactions;