import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import LoadingDots from './LoadingDots';

const UploadForm = ({ showMessage, showSubmit, loggedInEmail }) => {
  const [fileInput, setFileInput] = useState(null);
  const [renameInputs, setRenameInputs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFileInput(e.target.files);
    prepareRenameInputs(e.target.files);
  };

  const prepareRenameInputs = (files) => {
    const inputs = Array.from(files).map((file, index) => ({
      originalName: file.name,
      newName: file.name,
      bankName: '',
      accountNo: ''
    }));
    setRenameInputs(inputs);
  };

  const handleRenameChange = (index, field, value) => {
    const newRenameInputs = [...renameInputs];
    newRenameInputs[index][field] = value;
    setRenameInputs(newRenameInputs);
  };

  const uploadData = () => {
    if (!fileInput || fileInput.length === 0) {
      showMessage("Please select files to upload.", true);
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('email', loggedInEmail); // Use the actual logged-in email

    Array.from(fileInput).forEach((file, index) => {
      const renamedFile = renameInputs[index].newName || file.name;
      const bankName = renameInputs[index].bankName;
      const accountNo = renameInputs[index].accountNo || 'test';

      formData.append('files', file, renamedFile);
      formData.append('bank_names', bankName);
      formData.append('account_nos', accountNo);
    });

    fetch('https://api.myexpenditure.com/api2/upload_data', {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (!response.ok) throw new Error('Failed to upload files');
        return response.json();
      })
      .then(data => {
        showMessage(`Files uploaded successfully! Duplicate entries skipped: ${data.duplicate_count || 0}`);
        showSubmit();
        setLoading(false);
      })
      .catch(() => {
        showMessage('An error occurred while uploading the files.', true);
        setLoading(false);
      });
  };

  return (
    <div style={{ height: '100vh', overflowY: 'auto' }}>
      <Form>
        <Form.Group>
          <Form.Label>Upload Files</Form.Label>
          <Form.Control type="file" multiple accept=".csv, .xls" onChange={handleFileChange} />
        </Form.Group>
        {renameInputs.map((input, index) => (
          <div key={index}>
            <Form.Group>
              <Form.Label>Rename {input.originalName}</Form.Label>
              <Form.Control type="text" value={input.newName} onChange={(e) => handleRenameChange(index, 'newName', e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Bank Name</Form.Label>
              <Form.Control type="text" value={input.bankName} onChange={(e) => handleRenameChange(index, 'bankName', e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Account Number (Optional)</Form.Label>
              <Form.Control type="text" value={input.accountNo} onChange={(e) => handleRenameChange(index, 'accountNo', e.target.value)} />
            </Form.Group>
          </div>
        ))}
        <Button onClick={uploadData}>Upload</Button>
        {loading && <LoadingDots />}
      </Form>
    </div>
  );
};

export default UploadForm;
